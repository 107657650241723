/**
 * Retourne un prix en TTC
 */
export function ttc (ht) {
    return ht * 1.2;
}

export function getPriceFormatter (locale = 'fr-FR'){
    return new Intl.NumberFormat(locale , {
        style: 'currency',
        currency: 'EUR',

        // These options are needed to round to whole numbers if that's what you want.
        //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });
}

export function displayPriceHt (ht){
    const formatter = getPriceFormatter()
    return formatter.format(ht);
}


export function displayPriceTtc (ht){
    return displayPriceHt(ttc(ht))
}

