import {Calendar} from '@fullcalendar/core';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';
import allLocales from '@fullcalendar/core/locales-all';
import React from 'react';
import Chart from 'chart.js/auto';
import moment from 'moment';

export class PlanningCalendar extends HTMLElement
{
	constructor()
	{
		console.log('13');
		super();
		this.setDefaultValue();
	}

	connectedCallback()
	{
		this.generateCalendar();
		this.displayRefusalForm();
		this.deleteSchedule();
		this.closeModalDeleteSchedule();
	}

	generateCalendar()
	{
		let containerCalendar = this.querySelector('.calendarContainer'),
			_w = $(window).width();

		if (containerCalendar)
		{
			this.formatEvent();
			containerCalendar.innerHTML = '';
			let nbSchedule              = 0;
			new Calendar(containerCalendar, {
				plugins        : [timeGridPlugin],
				height  	   : _w < 600 ? '2500px' :  'auto',
				contentHeight  : _w < 600 ? '2500px' :  'auto',
				initialView    : 'timeGrid',
				headerToolbar  : false,
				locales        : allLocales,
				locale         : 'fr',
				allDaySlot     : false,
				slotMinTime    : this.props.startTime,
				slotMaxTime    : this.props.endTime,
				slotDuration   : '00:20:00',
				timeZone       : 'UTC',
				visibleRange   : {
					start: this.props.current,
					end  : this.props.current
				},
				events         : this.events,
				eventDidMount  : info => {
					nbSchedule++;
					info.el.innerHTML = info.timeText + ' ' + info.event.title;
				},
				slotLabelFormat: [
					{hour: '2-digit', minute: '2-digit'}, // top level of text
				]
			}).render();
			let eventContainer = document.querySelector('.fc-view-harness.fc-view-harness-active');
			if (eventContainer)
			{
				eventContainer.style.height = `${nbSchedule * 21 + (4 * 21) + 20}px`
			}

			let fcTimegridEventHarness = document.querySelectorAll('.fc-timegrid-event-harness');
			fcTimegridEventHarness.forEach(event => {
				event.style.zIndex = 'initial';
			})
		}
	}

	displayRefusalForm()
	{
		let refuseActions = this.querySelectorAll('.refuseAction');
		refuseActions.forEach(refuseAction => {
			refuseAction.onclick = e => {
				e.preventDefault();

				jQuery.ajax({
					url     : refuseAction.getAttribute('href'),
					type    : "POST",
					dataType: 'html',
					async   : false,
					headers : {"cache-control": "no-cache"},
					success : function (response) {
						let modalId = 'meeting-refusal-modal',
								modal   = document.querySelector('#' + modalId);

						if (modal)
						{
							modal.remove();
						}

						document.body.innerHTML += `
							<div id="${modalId}">
								<div class="modalContainer">
									<div class="modal-body">${response}</div>
								</div>
							</div>
							`;
						modal = document.querySelector('#' + modalId);

						if (modal)
						{
							let modalCloseBtn = modal.querySelector('.modalClose');
							if (modalCloseBtn)
							{
								modalCloseBtn.onclick = e => {
									e.preventDefault();
									modal.remove();
								}
							}
						}
					},
					error   : function (XMLHttpRequest, textStatus, errorThrown) {

					},
				});
			}
		});
	}

	setDefaultValue()
	{
		this.props  = {};
		this.events = [];
		Object.values(this.attributes).map(attribute => {
			this.props[attribute.name] = attribute.value;
		});
		this.props.current_locale = this.props.current_locale ?? 'en';

		console.log('coucou');
	}

	roundDate(date)
	{
		let coeff = 1000 * 60 * 5;
		return new Date(Math.round(date.getTime() / coeff) * coeff);
	}

	formatEvent()
	{
		let containerDataCalendar = this.querySelector('.containerDataCalendar');
		if (containerDataCalendar)
		{
			containerDataCalendar.querySelectorAll('.item').forEach(item => {

				if (item.getAttribute('start'))
				{
					this.props.startTime = item.getAttribute('startTime');
				}
				if (item.getAttribute('end'))
				{
					this.props.endTime = item.getAttribute('endTime');
				}

				let event = {
					id       : item.id,
					title    : item.innerHTML,
					start    : this.props.current + ' ' + item.getAttribute('startTime'), // moment(this.roundDate(new Date(String(this.props.current) + ' ' + item.getAttribute('startTime')))).format('YYYY-MM-DD HH:mm'),
					end      : this.props.current + ' ' + item.getAttribute('endTime'), // moment(this.roundDate(new Date(String(this.props.current) + ' ' + item.getAttribute('endTime')))).format('YYYY-MM-DD HH:mm'),
					className: item.className
				};

				this.events.push(event)
			})
		}
	};



	deleteSchedule()
	{
		let planningDeleteSchedules = this.querySelectorAll('.planningDeleteSchedule');
		planningDeleteSchedules.forEach(planningDeleteSchedule => {
			planningDeleteSchedule.onclick = e => {
				e.preventDefault();

				/* UPDATE THE FORM ACTION AND DAY INPUT VALUE*/
				let formDeleteSchedule = document.querySelector('#planning-delete-schedule-modal form');
				formDeleteSchedule.action = e.target.dataset.formAction;

				let dayInput = document.querySelector('#modal-delete-meeting-day');
				dayInput.value = e.target.dataset.day;

				let modalDeleteMeetingDetailSchedule = document.getElementById('modal-delete-meeting-detail-schedule');
				modalDeleteMeetingDetailSchedule.innerHTML = e.target.dataset.deleteDetailSchedule;

				let modaleDeleteSchedule = document.querySelector('#planning-delete-schedule-modal');
				modaleDeleteSchedule.style.display = 'block';
			}
		});
	}

	closeModalDeleteSchedule()
	{
		let closeBTN = document.querySelector('.close-modal-delete-schedule');

		closeBTN.addEventListener('click', function(event) {
			event.preventDefault();
			let modaleDeleteSchedule = document.querySelector('#planning-delete-schedule-modal');
			modaleDeleteSchedule.style.display = 'none';
		});

	}
}

customElements.define('planning-calendar', PlanningCalendar);
console.log('211');

export class PlanningChart extends HTMLElement
{
	connectedCallback()
	{
		const ctx = this.querySelector('canvas');

		new Chart(ctx, {
			type: 'doughnut',
			data: {
				datasets: [
					{
						label          : '',
						data           : [this.dataset.qty, this.dataset.sum - this.dataset.qty],
						backgroundColor: [
							'#6BAF25',
							'#C9E2B0',
						],
						hoverOffset    : 4
					}
				]
			},
		});
	}
}

customElements.define('planning-chart', PlanningChart);

export class listVisitor extends HTMLElement
{
	constructor()
	{
		super();
		this.setDefaultValue();
	}

	connectedCallback()
	{
		this.listenFilterInput();
		this.listenShowDetails();
	}

	listenFilterInput()
	{
		let planningSearch             = document.querySelector('#planningSearch');
		let planningSearchLabel        = document.querySelector('label[for="planningSearch"]');
		let planningSearchCustomerType = document.querySelector('#planningSearchCustomerType');
		let planningSearchActivity     = document.querySelector('#planningSearchActivity');
		let planningSearchVineyard       = document.querySelector('#planningSearchVineyard');
		let planningSearchCountry      = document.querySelector('#planningSearchCountry');
		let planningPageSelector       = document.querySelectorAll('#planningPageSelector');
		if (planningSearch && planningSearchLabel)
		{
			this.search                 = planningSearch.value;
			planningSearchLabel.onclick = e => {
				e.preventDefault();
				this.search = planningSearch.value;
				this.goToSearch();
			}
			planningSearch.onkeyup      = e => {
				// Number 13 is the "Enter" key on the keyboard
				if (e.keyCode === 13)
				{
					// Cancel the default action, if needed
					e.preventDefault();
					// Trigger the button element with a click
					planningSearchLabel.click();
				}
			}
		}
		if (planningSearchCustomerType)
		{
			let planningCustomerType = $('#planningSearchCustomerType');
			planningCustomerType.find(':selected').each((key, option) => {
				this.searchCustomerType.push(option.value);
			})
			planningCustomerType.select2();
			planningCustomerType.on('select2:select select2:unselect', () => {
				let selectedOptions     = planningCustomerType.find(':selected');
				this.searchCustomerType = [];
				selectedOptions.each((key, element) => {
					this.searchCustomerType.push(element.value);
				})
				this.goToSearch();
			})
		}
		if (planningSearchActivity)
		{
			this.searchActivity             = planningSearchActivity.value;
			planningSearchActivity.onchange = e => {
				console.log(e.target.value);
				this.searchActivity = e.target.value;
				this.goToSearch();
			}
		}
		if (planningSearchVineyard)
		{
			this.searchVineyard             = planningSearchVineyard.value;
			planningSearchVineyard.onchange = e => {
				this.searchVineyard = e.target.value;
				this.goToSearch();
			}
		}
		if (planningSearchCountry)
		{
			this.searchCountry             = planningSearchCountry.value;
			planningSearchCountry.onchange = e => {
				this.searchCountry = e.target.value;
				this.goToSearch();
			}
		}
		if (planningPageSelector.length > 0)
		{
			planningPageSelector.forEach(element => {
				element.onclick = e => {
					this.page = e.target.dataset.page;
					this.listenShowDetails();
				}
			})
		}
	}

	resetSelect2()
	{
		let select = document.querySelector('#planningSearchCustomerType');
		if (select)
		{
			let div = select.closest('.selectPlanningSearchCustomerType');
			if (div)
			{
				div.innerHTML = '';
				div.append(select);
				$('#planningSearchCustomerType').select2();
			}
		}
	}

	setDefaultValue()
	{
		this.props = {};
		Object.values(this.attributes).map(attribute => {
			this.props[attribute.name] = attribute.value;
		})
		this.search             = '';
		this.searchVineyard       = 0;
		this.searchActivity     = 0;
		this.searchCountry      = 0;
		this.searchCustomerType = [];
	}

	goToSearch()
	{
		let param       = '' +
											(this.search.length > 0 ? '&se=' + this.search : '') +
											(this.searchVineyard !== 0 ? '&ma=' + this.searchVineyard : '') +
											(this.searchActivity !== 0 ? '&ac=' + this.searchActivity : '') +
											(this.searchCountry !== 0 ? '&ctr=' + this.searchCountry : '') +
											(this.searchCustomerType.length > 0 ? '&ct=' + (this.searchCustomerType.join(',')) : '')
		window.location = this.props.path + '?' + param.replace(/^&/g, '')
	}
}

customElements.define('list-visitor', listVisitor);

export class scheduleList extends HTMLElement
{
	constructor()
	{
		super();
		this.setDefaultValue();
	}

	connectedCallback()
	{
		this.formatEvent();

		this.generateCalendar();
	}

	generateCalendar()
	{
		let container = document.querySelector('.scheduleContainer');
		if (container)
		{
			container.innerHTML = '';
			let calendar        = new Calendar(container, {
				plugins      : [listPlugin],
				initialView  : 'listWeek',
				events       : this.events,
				headerToolbar: false,
				locales      : allLocales,
				locale       : this.props.locale,
				eventDidMount: info => {
					let title = info.el.querySelector('.fc-list-event-title');
					if (title)
					{
						title.innerHTML = `${info.event.title}`;
					}
				}
			});
			calendar.gotoDate(new Date(this.props.start));
			calendar.render();
			this.querySelectorAll('.addEvent a').forEach(element => {
				element.onclick = e => {
					e.preventDefault();
					window.location = element.href;
				}
			})
		}
	}

	roundDate(date)
	{
		if (typeof date === "string")
		{
			date = new Date(date);
		}
		let coeff = 1000 * 60 * 5;
		return new Date(Math.round(date.getTime() / coeff) * coeff);
	}

	setDefaultValue()
	{
		this.props  = {};
		this.events = [];
		Object.values(this.attributes).map(attribute => {
			this.props[attribute.name] = attribute.value;
		});
	}

	formatEvent()
	{
		this.querySelectorAll('.containerDataSchedule > div.item').forEach(item => {
			if (item.getAttribute('startTime'))
			{
				this.props.startTime = item.getAttribute('startTime');
			}
			if (item.getAttribute('endTime'))
			{
				this.props.endTime = item.getAttribute('endTime');
			}
			if (item.getAttribute('day'))
			{
				this.props.day = item.getAttribute('day');
			}

			let event = {
				title    : item.innerHTML,
				start    : moment(this.props.day + ' ' + this.props.startTime).format(),
				end      : this.roundDate(moment(this.props.day + ' ' + this.props.endTime).format()),
				timeText : 'rr',
				className: 'pendingEvent'
			};

			this.events.push(event)
		})
	};
}

customElements.define('schedule-list', scheduleList);

export class infoBubble extends HTMLElement
{
	constructor()
	{
		super();
		this.setDefaultValue();
	}

	connectedCallback()
	{
		this.innerHTML   = {
			message: `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="message" class="svg-inline--fa fa-message infoBubble" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M511.1 63.1v287.1c0 35.25-28.75 63.1-64 63.1h-144l-124.9 93.68c-7.875 5.75-19.12 .0497-19.12-9.7v-83.98h-96c-35.25 0-64-28.75-64-63.1V63.1c0-35.25 28.75-63.1 64-63.1h384C483.2 0 511.1 28.75 511.1 63.1z"></path></svg>`,
			info   : `<svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="circle-info" class="svg-inline--fa fa-circle-info infoBubble" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
			<path fill="currentColor" d="M256 0C114.6 0 0 114.6 0 256s114.6 256 256 256s256-114.6 256-256S397.4 0 256 0zM256 464c-114.7 0-208-93.31-208-208S141.3 48 256 48s208 93.31 208 208S370.7 464 256 464zM296 336h-16V248C280 234.8 269.3 224 256 224H224C210.8 224 200 234.8 200 248S210.8 272 224 272h8v64h-16C202.8 336 192 346.8 192 360S202.8 384 216 384h80c13.25 0 24-10.75 24-24S309.3 336 296 336zM256 192c17.67 0 32-14.33 32-32c0-17.67-14.33-32-32-32S224 142.3 224 160C224 177.7 238.3 192 256 192z"></path>
			</svg>`,
		}[this.props.icon ?? 'info'];
		this.onmouseover = this.onmouseout = this.onmouseleave = this.showBubble;
	}

	showBubble(e)
	{
		let element = e.target;
		if (element.tagName !== 'INFO-BUBBLE')
		{
			element = element.closest('info-bubble');
		}
		if (element.tagName === 'INFO-BUBBLE')
		{
			e.preventDefault();
			this.deleteBubble(null, element)
			if (e.type === 'mouseover')
			{
				element.querySelector('.infoBubble').style.color = 'cyan';
				element.innerHTML += `<span class="infoBubble-message">${this.props.message}</span>`;
			}

			// setTimeout(() => {
			// 	this.deleteBubble(null, element)
			// }, 5000);
		}
	}

	deleteBubble(e, element = null)
	{
		if (!element)
		{
			element = e.target;
			if (element.tagName !== 'INFO-BUBBLE')
			{
				element = element.closest('info-bubble');
			}
			if (element.tagName === 'INFO-BUBBLE')
			{
				e.preventDefault();
			}
		}
		if (element.tagName === 'INFO-BUBBLE')
		{
			document.querySelectorAll('.infoBubble').forEach(element => {
				element.style.color = 'white'
			});
			document.querySelectorAll('.infoBubble-message').forEach(element => {
				element.remove();
			});
		}
	}

	setDefaultValue()
	{
		this.props  = {};
		this.events = [];
		Object.values(this.attributes).map(attribute => {
			this.props[attribute.name] = attribute.value;
		});
	}
}

customElements.define('info-bubble', infoBubble);

export class rankStars extends HTMLElement
{
	constructor()
	{
		super();
		this.setDefaultValue();
	}

	connectedCallback()
	{
		let html = '';
		for (let i = 1; i <= (this.props.max ?? 3); i++)
		{
			let rank      = this.props.rank ?? 0;
			let rankFloor = Math.floor(rank);
			let type      = i <= rankFloor ? 'full' : (!(rank % 1 === 0) && (i - 1) === rankFloor ? 'half' : 'empty')
			html += `<div class="star star-${type}">${this.props.stars[type]}</div>`;
		}

		if (this.querySelector('.rank'))
		{
			this.querySelector('.rank').innerHTML = html;
		}

		let favorites = this.querySelectorAll('.favorite a');
		favorites.forEach(favorite => {
			favorite.onclick = e => {
				e.preventDefault();
				let link = favorite.href;
				if (link)
				{
					let options = {
						method : 'POST',
						headers: {
							'Content-Type'    : 'application/json; charset=utf-8',
							'X-Requested-With': 'XMLHttpRequest',
						},
						mode   : 'cors',
						cache  : 'default'
					};

					fetch(link, options).then(response => {
						if (response.ok)
						{
							response.json().then(json => {
								if (json.result && json.result === 'success')
								{

									let favorites = this.querySelectorAll('.favorite a');
									favorites.forEach(favorite2 => {
										favorite2.classList.toggle('hidden');
									})
								}
							});
						}
						else
						{
							console.log('Mauvaise réponse du réseau');
						}
					}).catch(error => {
						console.log('Il y a eu un problème avec l\'opération fetch: ' + error.message);
					});
				}
			}
		})
	}

	setDefaultValue()
	{
		this.props  = {};
		this.events = [];
		Object.values(this.attributes).map(attribute => {
			this.props[attribute.name] = attribute.value;
		});
		this.props.stars = {
			empty: `<svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="star" class="svg-inline--fa fa-star" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M528.5 171.5l-146.4-21.29l-65.43-132.4C310.9 5.971 299.4-.002 287.1 0C276.6 0 265.1 5.899 259.3 17.8L193.8 150.2L47.47 171.5C21.2 175.3 10.68 207.6 29.72 226.1l105.9 102.1L110.6 474.6C107 495.3 123.6 512 142.2 512c4.932 0 10.01-1.172 14.88-3.75L288 439.6l130.9 68.7c4.865 2.553 9.926 3.713 14.85 3.713c18.61 0 35.21-16.61 31.65-37.41l-25.05-145.5l105.9-102.1C565.3 207.6 554.8 175.3 528.5 171.5zM407.4 326.2l26.39 153.7l-138.4-72.6c-4.656-2.443-10.21-2.442-14.87 .0008l-138.4 72.66l27.91-162.1l-117.9-114.7l154.7-22.51C211.1 179.9 216.5 176.6 218.8 171.9L288 32l69.21 139.9c2.333 4.716 6.834 7.983 12.04 8.74l154.7 22.49l-111.1 108.9C408.2 315.7 406.5 320.1 407.4 326.2z"></path></svg>`,
			half : `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star-sharp-half-stroke" class="svg-inline--fa fa-star-sharp-half-stroke" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M537.8 181.6h-177.6l-54.89-169.1C302.6 4.189 295.3 0 288 0S273.4 4.189 270.7 12.57L215.8 181.6H38.21c-17.6 0-24.92 22.54-10.68 32.9l143.7 104.5L116.3 488.1C112.2 500.9 122.3 512 133.7 512c3.552 0 7.227-1.09 10.62-3.557L288 403.1l143.7 104.5C435.1 510.9 438.8 512 442.3 512c11.36 0 21.49-11.13 17.35-23.89l-54.89-169.1l143.7-104.5C562.7 204.2 555.4 181.6 537.8 181.6zM376.6 280.2l-28.19 20.5l37.33 114.1L288 344.6V114.7l37.33 114.1h120.8L376.6 280.2z"></path></svg>`,
			full : `<svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="star" class="svg-inline--fa fa-star" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M316.7 17.8l65.43 132.4l146.4 21.29c26.27 3.796 36.79 36.09 17.75 54.59l-105.9 102.1l25.05 145.5c4.508 26.31-23.23 45.9-46.49 33.7L288 439.6l-130.9 68.7C133.8 520.5 106.1 500.9 110.6 474.6l25.05-145.5L29.72 226.1c-19.03-18.5-8.516-50.79 17.75-54.59l146.4-21.29l65.43-132.4C271.1-6.083 305-5.786 316.7 17.8z"></path></svg>`,
		}
	}
}

customElements.define('rank-stars', rankStars);

export class meetingList extends HTMLElement
{
	constructor()
	{
		super();
		this.setDefaultValue();
	}

	connectedCallback()
	{
		this.generateCalendar();
	}

	generateCalendar()
	{
		let containerCalendar = this.querySelector('.containerCalendar');
		if (containerCalendar)
		{
			this.formatEvent();
			containerCalendar.innerHTML = '';
			let calendar                = new Calendar(containerCalendar, {
				plugins        : [listPlugin],
				initialView    : 'listWeek',
				events         : this.events,
				eventDidMount  : info => {
					let title = info.el.querySelector('.fc-list-event-title');
					if (title)
					{
						title.innerHTML = `${info.event.title}`;
					}
				},
				headerToolbar  : false,
				locales        : allLocales,
				locale         : this.props.locale,
				slotLabelFormat: [
					{hour: '2-digit', minute: '2-digit'}, // top level of text
				]
			});
			calendar.gotoDate(new Date(this.props.start));
			calendar.render();

		}
	}

	setDefaultValue()
	{
		this.props  = {};
		this.events = [];
		Object.values(this.attributes).map(attribute => {
			this.props[attribute.name] = attribute.value;
		});
		this.props.current_locale = this.props.current_locale ?? 'en';
	}

	roundDate(date)
	{
		if (typeof date === "string")
		{
			date = new Date(date);
		}
		let coeff = 1000 * 60 * 5;
		return new Date(Math.round(date.getTime() / coeff) * coeff);
	}

	formatEvent()
	{
		let containerDataCalendar = this.querySelector('.containerData');
		if (containerDataCalendar)
		{
			containerDataCalendar.querySelectorAll('.item').forEach(item => {

				if (item.getAttribute('startTime'))
				{
					this.props.startTime = item.getAttribute('startTime');
				}
				if (item.getAttribute('endTime'))
				{
					this.props.endTime = item.getAttribute('endTime');
				}
				if (item.getAttribute('day'))
				{
					this.props.day = item.getAttribute('day');
				}

				let event = {
					title    : item.innerHTML,
					start    : moment(this.props.day + ' ' + this.props.startTime).format(),
					end      : this.roundDate(moment(this.props.day + ' ' + this.props.endTime).format()),
					className: 'pendingEvent'
				};

				this.events.push(event)
			})
		}
	};
}

customElements.define('meeting-list', meetingList);

export class RefusalForm extends HTMLElement
{
	constructor()
	{
		super();
		this.setDefaultValue();
	}

	connectedCallback()
	{
		this.displayRefusalForm();
	}

	displayRefusalForm()
	{
		this.onclick = e => {
			e.preventDefault();

			jQuery.ajax({
				url     : this.props.href,
				type    : "POST",
				dataType: 'html',
				async   : false,
				headers : {"cache-control": "no-cache"},
				success : function (response) {
					let modalId = 'meeting-refusal-modal',
							modal   = document.querySelector('#' + modalId);

					if (modal)
					{
						modal.remove();
					}

					document.body.innerHTML += `
							<div id="${modalId}">
								<div class="modalContainer">
									<div class="modal-body">${response}</div>
								</div>
							</div>
							`;
					modal = document.querySelector('#' + modalId);

					if (modal)
					{
						let modalCloseBtn = modal.querySelector('.modalClose');
						if (modalCloseBtn)
						{
							modalCloseBtn.onclick = e => {
								e.preventDefault();
								modal.remove();
							}
						}
					}
				},
				error   : function (XMLHttpRequest, textStatus, errorThrown) {

				},
			});
		}
	}

	setDefaultValue()
	{
		this.props  = {};
		this.events = [];
		Object.values(this.attributes).map(attribute => {
			this.props[attribute.name] = attribute.value;
		});
		this.props.current_locale = this.props.current_locale ?? 'en';
	}
}

customElements.define('refusal-action', RefusalForm);

export class CloseModal extends HTMLElement
{
	constructor()
	{
		super();
		this.setDefaultValue();
	}

	connectedCallback()
	{
		this.onclick = e => {
			e.preventDefault();
			if(this.props.idmodal){
				let modal = document.querySelector('#'+this.props.idmodal);
				if(modal){
					modal.remove();
				}
			}
		}
	}

	setDefaultValue()
	{
		this.props  = {};
		this.events = [];
		Object.values(this.attributes).map(attribute => {
			this.props[attribute.name] = attribute.value;
		});
		this.props.current_locale = this.props.current_locale ?? 'en';
	}
}

customElements.define('close-modal', CloseModal);

export class showDetail extends HTMLElement
{
	constructor()
	{
		super();
		this.setDefaultValue();

		this.onclick = e => {
			e.preventDefault();
			let event = this.closest('li');
			if (event)
			{
                let modal = event.querySelector('modal-dialog')
                modal.removeAttribute('hidden')
			}
		}
	}

	setDefaultValue()
	{
		this.props  = {};
		this.events = [];
		Object.values(this.attributes).map(attribute => {
			this.props[attribute.name] = attribute.value;
		});
		this.props.current_locale = this.props.current_locale ?? 'en';
	}

}

customElements.define('show-details', showDetail);

export class showMessage extends HTMLElement
{
	constructor()
	{
		super();
		this.setDefaultValue();

		this.onclick = e => {
			e.preventDefault();
			document.querySelectorAll('.formDetails').forEach(formDetail => {
				formDetail.remove();
			})

			let label  = this.props.label ?? '';
			let value  = this.props.value ?? '';
			let button = this.props.button ?? '';

			let table     = this.closest('table');
			let actualRow = this.closest('tr');

			let row = table.insertRow(actualRow.rowIndex + 1);
			row.classList.add('formDetails');
			row.insertCell(0);
			let cell2       = row.insertCell(1);
			cell2.colSpan   = 2;
			cell2.innerHTML = `<form action="${this.props.href}" method="get">
				<label for="detailtAppointment">${label}</label><br>
				<input type="text" name="dtl" class="w100" value="${value}"><br>
				<button type="submit" class="btn btn-primary btn-sm">${button}</button>
				</form>`;
		}
	}

	setDefaultValue()
	{
		this.props  = {};
		this.events = [];
		Object.values(this.attributes).map(attribute => {
			this.props[attribute.name] = attribute.value;
		});
		this.props.current_locale = this.props.current_locale ?? 'en';
	}

}

customElements.define('show-message', showMessage);

export class jitsiMeet extends HTMLElement
{
	constructor()
	{
		super();
		this.import();
		this.setDefaultValue();
		
		window.onload = () => {
			const api = new JitsiMeetExternalAPI("8x8.vc", {
				roomName: this.room,
				parentNode: this.target,
				jwt: this.jwt,
				configOverwrite: {
					prejoinPageEnabled: false,
					startWithAudioMuted: false,
					startWithVideoMuted: false,
					liveStreamingEnabled: false,
					fileRecordingsEnabled:false,
					toolbarButtons: [
						'camera', 'microphone', 'chat', 'fullscreen', 'desktop', 'select-background', 'raisehand', 'hangup'
					],
					defaultLanguage: this.lang
				}
			});
		  }
	}

	import() {
		let head = document.getElementsByTagName('head')[0];
		let script = document.createElement('script');
		
		script.setAttribute('type', 'text/javascript');
		script.setAttribute('src', 'https://8x8.vc/libs/external_api.min.js');
		head.appendChild(script)
	}

	setDefaultValue()
	{
		this.target  = document.querySelector('#jaas-container');
		this.room 	 = this.getAttribute('data-room');
		this.jwt 	 = this.getAttribute('data-jwt');
		this.lang 	 = this.getAttribute('data-lang');
	}
}
customElements.define('jitsi-meet', jitsiMeet);

