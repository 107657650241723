import { useEffect, useRef, useState } from "preact/hooks";
import { Spinner } from "../Animation/Spinner.jsx";
import { Modal } from "../Modal.jsx";
import { CollaboratorForm } from "./CollaboratorForm";
import { useAsyncEffect } from '/assets/functions/hooks.js'
import { flash } from '/assets/elements/Alert.js'
import { ApiError, jsonFetch, jsonFetchOrFlash } from '/assets/functions/api.js'
import { forceInputUppercase } from "../../functions/string.js";

export function CollaboratorModal ({show, onClose, onSubmit, handleSuccess, collaborator = null, target}) {
    const [form, setForm] = useState()
    const [loading, setLoading] = useState(false)
    const formContainer = useRef()

    const action = collaborator ? `/api/${target}/collaborators/form/${collaborator.id}` : `/api/${target}/collaborators/form`

    useAsyncEffect(async () => {
        if (!show) {
            setForm(null)
            return
        }
        
        setLoading(true)
        const response = await jsonFetchOrFlash(action, { method: 'GET' }, false)
        if (response) {
            setForm(response)
        }
        setLoading(false)
    }, [show])

    useEffect(() => {
        if (!form) return
        const htmlForm = formContainer.current.querySelector('form')
        if (htmlForm) {
            htmlForm.addEventListener('submit', handleSubmit)
            if (document.getElementById("collaborator_registration_form_lastname")) document.getElementById("collaborator_registration_form_lastname").addEventListener("keyup", forceInputUppercase);
        }
    }, [form])


    const handleSubmit = async e => {
        e.preventDefault()
        const formData = { ...Object.fromEntries(new FormData(formContainer.current.querySelector('form'))) }
        setLoading(true)
        collaborator ? await onSubmit(collaborator.id, formData) : onSubmit(formData)
        setLoading(false)
    }

    return (
        <Modal show={show} onClose={onClose}>
            <header>
                <h4>{ collaborator ? trans.editCollaborator : trans.newCollaborator }</h4>
            </header>
            {loading && (
                <Spinner />
            )}
            <div ref={formContainer} dangerouslySetInnerHTML={{ __html: form }} />
        </Modal>
    )
}