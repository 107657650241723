import { memo } from 'preact/compat'
import { canManage, isAuthenticated } from '/assets/functions/auth.js'
import { formatDate } from '/assets/functions/datetime.js'
import { Icon } from '../Icon.jsx'
import { useEffect, useRef, useState } from 'preact/hooks'
import { CollaboratorModal } from './CollaboratorModal.jsx'
import { IconButton } from '../Button.jsx'
import { Switch } from '../Switch.jsx'

export const Collaborator = memo (({ collaborator, target, editing, onEdit, onUpdate, onDelete, onToggleCanHavePlanning = () => {}, canHavePlanning = false }) => {
    const canEdit = canManage()
    const className = ['collaborator']
    const [showCollaboratorModal, setShowCollaboratorModal] = useState(false)
    const [hasPlanning, setPlanning] = useState(collaborator.hasPlanning)

    const handleEdit = canEdit
    ? e => {
        e.preventDefault()
        setShowCollaboratorModal(true)
        onEdit(collaborator)
    }
    : null

    const handleDelete = e => {
        e.preventDefault()
        onDelete(collaborator)
    }

    const handleSubmit = async (id, data) => {
        await onUpdate(id, data)
        setShowCollaboratorModal(false)
    }

    const handleSuccess = async (data) => {
        await onUpdate(data)
        setShowCollaboratorModal(false)
    }

    const handleToggleCanHavePlanning = async () => {
        const newCollaborator = await onToggleCanHavePlanning(collaborator.id)
        setPlanning(newCollaborator.hasPlanning)
    }

    if (!collaborator || !collaborator.title) return null
    return (
        <tr className={className.join(' ')}>
            <td className="collaborator__title">{collaborator.title.name}</td>
            <td className="collaborator__firstname">{collaborator.firstname}</td>
            <td className="collaborator__lastname">{collaborator.lastname}</td>
            <td className="collaborator__email">{collaborator.email}</td>
            <td className="collaborator__position">{collaborator.position.name}</td>
            {target == "exhibitor" &&
                <td className="collaborator__planning">
                    {canEdit && collaborator.id !== window.WORKSHOP.USER ? (
                        <Switch disabled={!canHavePlanning && !collaborator.hasPlanning} defaultChecked={hasPlanning} handleChange={handleToggleCanHavePlanning} checked={hasPlanning} />
                        ):(
                            <>{hasPlanning ? trans.yes : trans.no }</>
                        )}
                </td>
            }
            <td className="collaborator__actions text-right">
                {canEdit && collaborator.id !== window.WORKSHOP.USER && (
                    <>
                        <IconButton icon='edit' onClick={handleEdit} />
                        <IconButton icon='delete' onClick={handleDelete}  class="btn-danger"/>
                        <CollaboratorModal show={showCollaboratorModal} onClose={() => setShowCollaboratorModal(false)}  onSubmit={handleSubmit} collaborator={collaborator} target={target} />
                    </>
                )}
            </td>
        </tr>
    )
})