import { scrollTo } from '/assets/functions/animation.js'
import { normalizeString, wordStartWithInSentence } from '/assets/functions/string.js'

export class InteractivePlan extends HTMLElement {
    constructor() {
        super()
        this.liSelected = this.liSelected.bind(this)
        this.inputSearchChange = this.inputSearchChange.bind(this)
        this.activeArea = this.activeArea.bind(this)
        this.scrollSVGToItem = this.scrollSVGToItem.bind(this)
        this.openList = this.openList.bind(this)
        this.closeList = this.closeList.bind(this)
        this.openPopup = this.openPopup.bind(this)
        this.onImageScroll = this.onImageScroll.bind(this)
        this.closePopup = this.closePopup.bind(this)
        this.onDocumentClick = this.onDocumentClick.bind(this)
    }

    connectedCallback () {
        this.classList.add('interactive-plan')
        
        this.popup = this.querySelector('.popup')
        this.inputSearch = this.querySelector('input[type="search"][name="search_items"]')
        this.emptySearch = this.querySelector('#empty-search')
        this.list = this.querySelector('.list')
        this.lis = this.querySelectorAll('.list li');
        this.plan = this.querySelector('.plan')
        this.image = this.querySelector('.image')
        this.svg = this.image.querySelector('svg')
        this.listIsOpen = false

        document.addEventListener('click', (e) => this.onDocumentClick(e))

        this.inputSearch.addEventListener('search', (e) => this.inputSearchChange(e.target.value))
        this.inputSearch.addEventListener('keyup', (e) => this.inputSearchChange(e.target.value))
        this.inputSearch.addEventListener('focus', (e) => this.openList(e))
        
        this.emptySearch.addEventListener('click', (e) => this.emptyClicked())
        
        this.lis.forEach((li) => {
            li.addEventListener('click', (e) => this.liSelected(li))
        })

        this.image.addEventListener('scroll', (e) => this.onImageScroll(e))
    }

    emptyClicked () {
        this.inputSearch.value = ''
        this.inputSearchChange()
    }

    liSelected (li) {
        this.inputSearch.value = li.innerText
        this.inputSearchChange(this.inputSearch.value)

        this.activeArea(li)
    }

    inputSearchChange (value) {
        this.activeArea()
        if (value) {
            let searchText = normalizeString(value)
            this.lis.forEach((item) => {
                let itemText = normalizeString(item.innerText)
                if (itemText.startsWith(searchText)) {
                    item.style.display = 'block'
                } else {
                    if (!wordStartWithInSentence(searchText, itemText)) {
                        item.style.display = 'none'
                    } else {
                        item.style.display = 'block'
                    }
                }
            })
        } else {
            this.lis.forEach((item) => {
                item.style.display = 'block'
            })
        }
    }

    activeArea (li) {
        this.querySelectorAll('.is-active').forEach((item) => {
            item.classList.remove('is-active')
        })
        if (li !== undefined) {
            let id = li.id.replace('list__item-', '')
            li.classList.add('is-active')

            if (id.includes('-')) {
                let ids = id.split('-')
                for (let i = 0; i < ids.length; i++) {
                    id = ids[i];
                    if (i === 0) var item = this.querySelector(`.image #image__item-${id}`)
                    this.querySelector(`.image #image__item-${id}`).classList.add('is-active')   
                }
            } else {
                console.log(this.querySelector(`.image #image__item-${id}`));
                var item = this.querySelector(`.image #image__item-${id}`)
                item.classList.add('is-active')
            }

            this.scrollSVGToItem(item)
            scrollTo(this.image)
            this.openPopup(li, item)
        }
    }

    scrollSVGToItem (item) {        
        const imagePos = this.image.getBoundingClientRect();
        const svgPos = this.svg.getBoundingClientRect();
        const coeff = (svgPos.width / 842)
        const svgItem = item.firstElementChild
        const itemPos = {
            top: svgItem.getAttribute('y') ? parseFloat(svgItem.getAttribute('y')) : parseFloat(svgItem.getAttribute('cy')),
            left: svgItem.getAttribute('x') ? parseFloat(svgItem.getAttribute('x')) : parseFloat(svgItem.getAttribute('cx')),
        }

        this.image.scrollTo({
            top: (svgPos.height - itemPos.top) / 2,
            left: (itemPos.left * coeff - (imagePos.width / 2 )),
            behavior: 'smooth'
        })
    }

    onDocumentClick (e) {
        if (this.listIsOpen) {
            if (!this.list.contains(e.target) && e.target !== this.inputSearch) {
                this.closeList()
            }
        }
        if (this.popupIsOpen) {
            if (!this.popup.contains(e.target)) {
                this.closePopup()
            }
        }
    }

    openList () {
        setTimeout(() => {
            this.inputSearch.setAttribute('aria-expanded', 'true')
            this.list.removeAttribute('aria-hidden', 'true')
            this.list.removeAttribute('hidden')
            this.listIsOpen = true
        }, 150)
    }

    closeList () {
        setTimeout(() => {
            this.inputSearch.removeAttribute('aria-expanded')
            this.list.setAttribute('aria-hidden', 'true')
            this.list.setAttribute('hidden', 'hidden')
            this.listIsOpen = false
        }, 150)
    }

    onImageScroll (e) {
        this.closePopup()
    }

    openPopup (li, item) {
        this.popup.querySelector('.popup__content').innerHTML = `
        ${li.dataset.urlLogo ? `<img src="${li.dataset.urlLogo}"/>` : `<p>${li.dataset.brandName}</p>`}
        `
        setTimeout(() => {
            const itemPos = item.getBoundingClientRect();
            const parentPos = this.image.getBoundingClientRect();
            const itemOffset = {
                top: itemPos.top - parentPos.top,
                left: itemPos.left - parentPos.left
            }

            this.popup.style.top = itemOffset.top - 100 + 'px'
            this.popup.style.left = itemOffset.left - 75 + (itemPos.width / 2) + 'px'

            this.inputSearch.setAttribute('aria-expanded', 'true')
            this.popup.removeAttribute('aria-hidden', 'true')
            this.popup.removeAttribute('hidden')
            this.popupIsOpen = true
        }, 1000)
        this.popup.focus()
    }

    closePopup () {
        setTimeout(() => {
            this.inputSearch.removeAttribute('aria-expanded')
            this.popup.setAttribute('aria-hidden', 'true')
            this.popup.setAttribute('hidden', 'hidden')
            this.popupIsOpen = false
        }, 150)
    }

}