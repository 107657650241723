import Chart from 'chart.js/auto';

export default class CustomChart extends HTMLElement
{
	connectedCallback () {
        this.classList.add('chart')
		const ctx = this.querySelector('canvas');
        
		new Chart(ctx, {
			type: 'doughnut',
			data: {
				datasets: [
					{
						label          : '',
						data           : [this.dataset.qty, this.dataset.sum - this.dataset.qty],
						backgroundColor: [
							'#4689c8',
							'#80afddcc',
						],
						hoverOffset    : 4
					}
				]
			},
		});
	}
}