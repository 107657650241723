export default class ListUserFilters extends HTMLElement
{
	constructor()
	{
		super();
		this.setDefaultValue();
	}

	connectedCallback()
	{
		this.listenFilterInput();
	}

	listenFilterInput()
	{
		let planningSearch             = this.querySelector('#planningSearch');
		let planningSearchLabel        = this.querySelector('label[for="planningSearch"]');
		let planningSearchCustomerType = this.querySelector('#planningSearchCustomerType');
		let planningSearchActivity     = this.querySelector('#planningSearchActivity');
		let planningSearchVineyard       = this.querySelector('#planningSearchVineyard');
		let planningSearchCountry      = this.querySelector('#planningSearchCountry');
		let planningMainActivity   = this.querySelector('#planningMainActivity');
		let planningWantedActivities   = this.querySelector('#planningWantedActivities');
		let planningRequestedActivities   = this.querySelector('#planningRequestedActivities');
		let planningFeaturedProducts   = this.querySelector('#planningFeaturedProducts');
		let planningClientele   = this.querySelector('#planningClientele');
		let planningPageSelector       = this.querySelectorAll('#planningPageSelector');
		let planningSeeOnlyAvailables = document.querySelector('#planningSeeOnlyAvailables');
		let planningCheckOnlyLastest = document.querySelector('#checkOnlyLastest');
		let planningSpeakLang = document.querySelector('#element-search-lg');
		let planningNbEmployee = document.querySelector('#element-search-nbs');
		let planningTourimeHand = document.querySelector('#element-search-th');
		let planningEnvDur = document.querySelector('#element-search-ede');
		let vineyardsAndDiscoveriesLabel = document.querySelector('#element-search-vdl');
		let skiGroupsVolume = document.querySelector('#element-search-vgsg');
		let skiIndividualsVolume = document.querySelector('#element-search-vgsi');
		let isQualiteTourisme = document.querySelector('#element-search-iqt');

		if (planningSearch && planningSearchLabel)
		{
			this.search                 = planningSearch.value;
			planningSearchLabel.onclick = e => {
				e.preventDefault();
				this.search = planningSearch.value;
				this.goToSearch();
			}
			planningSearch.onkeyup      = e => {
				// Number 13 is the "Enter" key on the keyboard
				if (e.keyCode === 13)
				{
					// Cancel the default action, if needed
					e.preventDefault();
					// Trigger the button element with a click
					planningSearchLabel.click();
				}
			}
		}
		if (planningSearchCustomerType)
		{
			setTimeout(() => {
				let planningCustomerType = jQuery('#planningSearchCustomerType');
				planningCustomerType.find(':selected').each((key, option) => {
					this.searchCustomerType.push(option.value);
				})
				let mThis = this;
				planningCustomerType.change(function(e) {
					let selectedOptions      = planningCustomerType.find(':selected');
					mThis.searchCustomerType = [];
					selectedOptions.each((key, element) => {
						mThis.searchCustomerType.push(element.value);
					})
					mThis.goToSearch();
				})
			},300)
		}
		if (planningFeaturedProducts)
		{
			this.searchFeaturedProducts             = planningFeaturedProducts.value;
			planningFeaturedProducts.onchange = e => {
				this.searchFeaturedProducts = e.target.value;
				this.goToSearch();
			}
		}
		if (planningClientele)
		{
			this.searchClientele             = planningClientele.value;
			planningClientele.onchange = e => {
				this.searchClientele = e.target.value;
				this.goToSearch();
			}
		}
		if (planningMainActivity)
		{
			this.searchMainActivity             = planningMainActivity.value;
			planningMainActivity.onchange = e => {
				this.searchMainActivity = e.target.value;
				this.goToSearch();
			}
		}
		if (planningRequestedActivities)
		{
			this.searchRequestedActivities            = planningRequestedActivities.value;
			planningRequestedActivities.onchange = e => {
				this.searchRequestedActivities = e.target.value;
				this.goToSearch();
			}
		}
		if (planningWantedActivities)
		{
			this.searchWantedActivities             = planningWantedActivities.value;
			planningWantedActivities.onchange = e => {
				this.searchWantedActivities = e.target.value;
				this.goToSearch();
			}
		}
		if (planningSeeOnlyAvailables)
		{
			this.searchSeeOnlyAvailables  = planningSeeOnlyAvailables.checked ? 1 : 0;
			planningSeeOnlyAvailables.onchange = e => {
                this.searchSeeOnlyAvailables = e.target.checked ? 1 : 0;
				this.goToSearch();
			}
		}

		if (planningCheckOnlyLastest)
		{
			this.searchCheckOnlyLastest  = planningCheckOnlyLastest.checked ? 1 : 0;
			planningCheckOnlyLastest.onchange = e => {
                this.searchCheckOnlyLastest = e.target.checked ? 1 : 0;
				this.goToSearch();
			}
		}


		if (planningSearchActivity)
		{
			this.searchActivity             = planningSearchActivity.value;
			planningSearchActivity.onchange = e => {
				console.log(e.target.value);
				this.searchActivity = e.target.value;
				this.goToSearch();
			}
		}
		if (planningSearchVineyard)
		{
			this.searchVineyard             = planningSearchVineyard.value;
			planningSearchVineyard.onchange = e => {
				this.searchVineyard = e.target.value;
				this.goToSearch();
			}
		}
		if (planningSearchCountry)
		{
			this.searchCountry             = planningSearchCountry.value;
			planningSearchCountry.onchange = e => {
				this.searchCountry = e.target.value;
				this.goToSearch();
			}
		}
		if (planningPageSelector.length > 0)
		{
			planningPageSelector.forEach(element => {
				element.onclick = e => {
					this.page = e.target.dataset.page;
				}
			})
		}

		if (planningSpeakLang)
		{
			this.searchSpeakLang            = planningSpeakLang.value;
			planningSpeakLang.onchange = e => {
				this.searchSpeakLang = e.target.value;
				this.goToSearch();
			}
		}

		if (planningNbEmployee)
		{
			this.searchNbEmployee            = planningNbEmployee.value;
			planningNbEmployee.onchange = e => {
				this.searchNbEmployee = e.target.value;
				this.goToSearch();
			}
		}

		if (planningTourimeHand)
		{
			this.searchTourimeHand            = planningTourimeHand.value;
			planningTourimeHand.onchange = e => {
				this.searchTourimeHand = e.target.value;
				this.goToSearch();
			}
		}

		if (planningEnvDur)
		{
			this.searchEnvDur            = planningEnvDur.value;
			planningEnvDur.onchange = e => {
				this.searchEnvDur = e.target.value;
				this.goToSearch();
			}
		}

		if (vineyardsAndDiscoveriesLabel)
		{
			this.searchVineyardsAndDiscoveriesLabel            = vineyardsAndDiscoveriesLabel.value;
			vineyardsAndDiscoveriesLabel.onchange = e => {
				this.searchVineyardsAndDiscoveriesLabel = e.target.value;
				this.goToSearch();
			}
		}

		if (skiGroupsVolume)
		{
			this.searchskiGroupsVolume            = skiGroupsVolume.value;
			skiGroupsVolume.onchange = e => {
				this.searchskiGroupsVolume = e.target.value;
				this.goToSearch();
			}
		}

		if (skiIndividualsVolume)
		{
			this.searchskiIndividualsVolume            = skiIndividualsVolume.value;
			skiIndividualsVolume.onchange = e => {
				this.searchskiIndividualsVolume = e.target.value;
				this.goToSearch();
			}
		}

		if (isQualiteTourisme)
		{
			this.searchisQualiteTourisme            = isQualiteTourisme.value;
			isQualiteTourisme.onchange = e => {
				this.searchisQualiteTourisme = e.target.value;
				this.goToSearch();
			}
		}

	}

	// resetSelect2()
	// {
	// 	let select = this.querySelector('#planningSearchCustomerType');
	// 	if (select)
	// 	{
	// 		let div = select.closest('.selectPlanningSearchCustomerType');
	// 		if (div)
	// 		{
	// 			div.innerHTML = '';
	// 			div.append(select);
	// 			$('#planningSearchCustomerType').select2();
	// 		}
	// 	}
	// }

	setDefaultValue()
	{
		this.props = {};
		Object.values(this.attributes).map(attribute => {
			this.props[attribute.name] = attribute.value;
		})
		this.search             = '';
		this.searchVineyard       = 0;
		this.searchActivity     = 0;
		this.searchCountry      = 0;
		this.searchCustomerType = [];
		this.searchMainActivity = 0;
		this.searchRequestedActivities = 0;
		this.searchWantedActivities = 0;
		this.searchFeaturedProducts = 0;
		this.searchClientele = 0;
		this.searchSeeOnlyAvailables  = 0;
		this.searchCheckOnlyLastest  = 0;
	}

	goToSearch()
	{
		let param       = '' +
            ((typeof this.search.length != 'undefined' && this.search.length > 0) ? '&se=' + this.search : '') +
			((typeof this.searchSeeOnlyAvailables != 'undefined' && this.searchSeeOnlyAvailables !== 0) ? '&soa=' + this.searchSeeOnlyAvailables : '') +
            ((typeof this.searchVineyard != 'undefined' && this.searchVineyard !== 0) ? '&ma=' + this.searchVineyard : '') +
            ((typeof this.searchActivity != 'undefined' && this.searchActivity !== 0) ? '&ac=' + this.searchActivity : '') +
            ((typeof this.searchCountry != 'undefined' && this.searchCountry !== 0) ? '&ctr=' + this.searchCountry : '') +
            ((typeof this.searchMainActivity != 'undefined' && this.searchMainActivity !== 0) ? '&ac=' + this.searchMainActivity : '') +
            ((typeof this.searchRequestedActivities != 'undefined' && this.searchRequestedActivities !== 0) ? '&ra=' + this.searchRequestedActivities : '') +
            ((typeof this.searchWantedActivities != 'undefined' && this.searchWantedActivities !== 0) ? '&wa=' + this.searchWantedActivities : '') +
            ((typeof this.searchClientele != 'undefined' && this.searchClientele !== 0) ? '&cc=' + this.searchClientele : '') +
            ((typeof this.searchFeaturedProducts != 'undefined' && this.searchFeaturedProducts !== 0) ? '&pc=' + this.searchFeaturedProducts : '') +
            ((typeof this.searchCheckOnlyLastest != 'undefined' && this.searchCheckOnlyLastest !== 0) ? '&lastest=' + this.searchCheckOnlyLastest : '') +

            ((typeof this.searchSpeakLang != 'undefined' && this.searchSpeakLang !== 0) ? '&lg=' + this.searchSpeakLang : '') +
            ((typeof this.searchNbEmployee != 'undefined' && this.searchNbEmployee !== 0) ? '&nbs=' + this.searchNbEmployee : '') +
            ((typeof this.searchTourimeHand != 'undefined' && this.searchTourimeHand !== 0) ? '&th=' + this.searchTourimeHand : '') +
            ((typeof this.searchEnvDur != 'undefined' && this.searchEnvDur !== 0) ? '&ede=' + this.searchEnvDur : '') +
            ((typeof this.searchVineyardsAndDiscoveriesLabel != 'undefined' && this.searchVineyardsAndDiscoveriesLabel !== 0) ? '&vdl=' + this.searchVineyardsAndDiscoveriesLabel : '') +
            ((typeof this.searchskiGroupsVolume != 'undefined' && this.searchskiGroupsVolume !== 0) ? '&vgsg=' + this.searchskiGroupsVolume : '') +
            ((typeof this.searchskiIndividualsVolume != 'undefined' && this.searchskiIndividualsVolume !== 0) ? '&vgsi=' + this.searchskiIndividualsVolume : '') +
            ((typeof this.searchisQualiteTourisme != 'undefined' && this.searchisQualiteTourisme !== 0) ? '&iqt=' + this.searchisQualiteTourisme : '') +

			((typeof this.searchCustomerType.length != 'undefined' && this.searchCustomerType.length > 0) ? '&ct=' + (this.searchCustomerType.join(',')) : '')
		window.location = this.props.path + '?' + param.replace(/^&/g, '')
	}
}