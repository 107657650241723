import { createPortal } from 'preact/compat'
import { useEffect, useRef, useState } from 'preact/hooks'
import { Icon } from './Icon.jsx'

export function Modal ({ children, show = true, onClose, padding, style, className }) {
    const [shouldRender, setRender] = useState(show)
    const ref = useRef(null);


    useEffect(() => {
        if (show) setRender(true)
    }, [show])

    useEffect(() => {
        document.body.style.overflow = "auto"
        if (shouldRender) {
            document.body.style.overflow = "hidden"
        }
    }, [shouldRender])
    
    const onAnimationEnd = e => {
        if (typeof onClose === 'function') {
            if (!show && e.animationName === 'modalOut') setRender(false)
        } else {
            if (e.animationName === 'modalOut') setRender(false)
        }
    }
    
    const onClick = (e) => {
        if (e.target === e.currentTarget) {
            if (typeof onClose === 'function') {
                onClose()
            } else {
                ref.current.classList.add('out')
            }
        }
    }

    useEffect(() => {      
        const handler = e => {
            if (e.key === 'Escape' && shouldRender) {
                if (typeof onClose === 'function') {
                    onClose()
                } else {
                    ref.current.classList.add('out')
                }
            }
        }
      
        window.addEventListener("keyup", handler);
        return () => window.removeEventListener("keyup", handler)
      }, [shouldRender])

    if (!shouldRender) {
        return null
    }

    return createPortal(
        <div
            className='modal-dialog'
            style={{ animation: `${show ? 'modalIn' : 'modalOut'} .3s both` }}
            onAnimationEnd={onAnimationEnd}
            onClick={onClick}
            ref={ref}
        >
            <div className="modal-box">
                <button class="modal-close" onClick={onClose || (() => ref.current.classList.add('out'))}>
                    <Icon name='cross' />
                </button>
                {children}
            </div>
        </div>,
        document.body
    )
}