/**
 * Vérifie si l'utilisateur est admin
 *
 * @return {boolean}
 */
export function isAdmin () {
    return window.WORKSHOP.ADMIN === true
}

/**
 * Vérifie si l'utilisateur est manager de l'inscription
 *
 * @return {boolean}
 */
export function isRegistrationManager () {
    return window.WORKSHOP.REGISTRATION_MANAGER !== null
}
/**
 * Vérifie si l'utilisateur est admin
 *
 * @return {boolean}
 */
 export function isRegistrationEditable () {
    return window.canEditForm === true
}

/**
 * Vérifie si l'utilisateur est connecté
 *
 * @return {boolean}
 */
export function isAuthenticated () {
    return window.WORKSHOP.USER !== null
}

/**
 * Renvoie l'id de l'utilisateur
 *
 * @return {number|null}
 */
export function getUserId () {
    return window.WORKSHOP.USER
}

/**
 * Vérifie si l'utilisateur connecté correspond à l'id passé en paramètre
 *
 * @param {number} userId
 * @return {boolean}
 */
export function canManage (id = null) {
    if (!isRegistrationEditable())
        return false;
        
    if (isAdmin()) {
        return true
    }
    if (isRegistrationManager()) {
        return true
    }
    if (!id) {
        return false
    }
    return window.WORKSHOP.USER === parseInt(id, 10)
}
