import { scrollBottom } from '/assets/functions/animation.js'

export class ChatConversations extends HTMLElement {
    constructor ({ username } = {}) {
        super()
        if (username !== undefined) {
            this.username = username
        }
        this.hubUrl = window.WORKSHOP.HUBURL
        this.selectedConversationId = null

        this.loadConversations = this.loadConversations.bind(this)
        this.loadMessages = this.loadMessages.bind(this)
        this.postMessage = this.postMessage.bind(this)

        this.messagesContainer = this.querySelector('#messages')
        this.conversationsContainer = this.querySelector('#conversations')
        this.inputContainer = this.querySelector('#input')
    }

    async connectedCallback () {
        this.username = this.username || this.getAttribute('username')
        this.classList.add('chat')
        this.classList.add('chat-conversations')
        await this.loadConversations();

        this.inputContainer.querySelector('form').addEventListener('submit', this.postMessage)

        let url = new URL(this.hubUrl);
        url.searchParams.append('topic', `/conversations/${this.username}`)
        const eventSource = new EventSource(url, { withCredentials: true })
        eventSource.onmessage = (e) => {
            this.loadConversations()
            const message = JSON.parse(e.data)
            message.createdAt = new Date(message.createdAt)
            if (message.conversation.id == this.selectedConversationId) {
                this.displayNewMessage(message)
            }
        }
    }

    displayNewMessage (message) {
        this.messagesContainer.innerHTML += `
        <div class="message${ message.mine ? ' mine' : '' }">
            <div class="rounded">
                <p>${ message.content }</p>
            </div>
            <p class="small text-muted">${ message.createdAt.toLocaleString('fr-FR', { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" }) }</p>
        </div>
        `
        scrollBottom(this.messagesContainer)
    }

    async postMessage (e) {
        e.preventDefault()

        if (!this.selectedConversationId) return;

        let formData = new FormData();
        let content = this.inputContainer.querySelector('input[type="text"]').value
        if (content === '') return;
        formData.append('content', content);

        fetch(`/messages/${this.selectedConversationId}`, { method: "POST", body: formData })

        this.displayNewMessage({
            mine: true, content: content, createdAt: new Date()
        })

        this.inputContainer.querySelector('input[type="text"]').value = '';

        this.loadConversations()
    }

    async loadMessages (e) {
        const conversationId = e.target.closest('.conversation').dataset.conversationId
        this.selectedConversationId = conversationId

        if (e.target.type === 'checkbox') {
            fetch(`/conversations/toggle/read/${this.selectedConversationId}`, { method: "POST" })
            return;
        }

        this.messagesContainer.innerHTML = ''
        if (!conversationId) return console.log('No Conversation ID')

        const response = await fetch(`/messages/${this.selectedConversationId}?ajax=1`)
        if (response.status >= 200 && response.status < 300) {
            const data = await response.text()
            this.messagesContainer.innerHTML = data
        } else {
            console.error(response)
        }
        scrollBottom(this.messagesContainer)
    }

    async loadConversations () {
        this.conversationsContainer.innerHTML = ''
        const response = await fetch(`/conversations?ajax=1`)
        if (response.status >= 200 && response.status < 300) {
            const data = await response.text()
            this.conversationsContainer.innerHTML = data
        } else {
            console.error(response)
        }
        this.conversationsContainer.querySelectorAll('.conversation').forEach(conversation => conversation.addEventListener('click', this.loadMessages));
    }

}
