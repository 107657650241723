import { jsonFetchOrFlash } from '/assets/functions/api.js'

/**
 * Représentation d'un collaborator de l'API
 * @typedef {{id: number, amount: float, type: string, label: string, isTransfer: boolean, invoice: Invoice, depositAccount: DepositAccount, pastCollaborator: boolan, createdAt: number, updatedAt: number}} Collaborator
 */

/**
 * @param {number} target
 * @return {Promise<Collaborator[]>}
 */
export async function getAllCollaborators (target) {
    return await jsonFetchOrFlash(`/api/${target}/collaborators`)
}

/**
 * @param {number} target
 * @return {Promise<Collaborator[]>}
 */
export async function getCollaboratorsRemaining (target) {
    return await jsonFetchOrFlash(`/api/${target}/collaborators/remaining`)
}

/**
 * @param {number} target
 * @return {Promise<Collaborator[]>}
 */
export async function getCollaboratorsPlanningRemaining (target) {
    return await jsonFetchOrFlash(`/api/${target}/collaborators/planning-remaining`)
}

/**
 * @param {{target: number, username: ?string, email: ?string, content: string}} data
 * @return {Promise<Object>}
 */
export async function addCollaborator (target, body) {
    return jsonFetchOrFlash(`/api/${target}/collaborators/form`, {
        method: 'POST',
        body
    })
}

/**
 * @param {string} target
 * @param {int} id
 * @return {Promise<Object>}
 */
export async function deleteCollaborator (target, id) {
    return jsonFetchOrFlash(`/api/${target}/collaborator/delete/${id}`, {
        method: 'DELETE'
    })
}

/**
 * @param {int} id
 * @param {object} data
 * @return {Promise<Collaborator>}
 */
export async function updateCollaborator (target, id, data) {
    return jsonFetchOrFlash(`/api/${target}/collaborators/form/${id}`, {
        method: 'PUT',
        body: JSON.stringify(data)
    })
}

/**
 * @param {int} id
 * @param {boolean} hasPlanning
 * @return {Promise<Collaborator>}
 */
export async function toggleCollaboratorPlanning (target, id) {
    return jsonFetchOrFlash(`/api/${target}/collaborators/toggle-planning/${id}`, {
        method: 'PUT'
    })
}
