
import { createElement } from '/assets/functions/dom.js'

/**
 * Element permettant de 
 *
 * @property {HTMLTableElement} table
 * @property {HTMLDivElement} meetingList
 */
export class VisitorActivityRegistrationForm extends HTMLFormElement {
    async connectedCallback () {
        var form = this;

        this.wantedActivitiesCheckbox = this.querySelectorAll(`[name="${this.name}[wantedActivities][]"]`)
        this.wantedActivitiesCheckbox.forEach(function (element) {
            form.onWantedActivitiesCheckboxChange({ target: element })
            element.addEventListener('change', e => form.onWantedActivitiesCheckboxChange({ target: e.target }))
        });
        
        this.isFamtripParticipateSelect = this.querySelector(`#${this.name}_isFamtripParticipate`)
        this.onIsFamtripParticipateSelectChange({ target: this.isFamtripParticipateSelect })
        this.isFamtripParticipateSelect.addEventListener('change', e => this.onIsFamtripParticipateSelectChange({ target: e.target }))

        this.famtripProgramsSelects = this.querySelectorAll(`#famtrip_programs select`)
        this.famtripProgramsSelects.forEach(function (element) {
            form.onFamtripProgramsSelectChange({ target: element })
            element.addEventListener('change', e => form.onFamtripProgramsSelectChange({ target: e.target }))
        });

    }

    onFamtripProgramsSelectChange({ target }) {
        let value = target.value,
            container =  this.querySelector('#' + target.dataset.alias + '_help'),
            error     =  this.querySelector('#' + target.dataset.alias + '_error')

        error.setAttribute('hidden', 'hidden')
        container.setAttribute('hidden', 'hidden')

        if (!this.checkFamtripProgramsChange(target)) {
            target.value = ''
            error.removeAttribute('hidden')
            return false
        }

        if (value) {
            container.removeAttribute('hidden')
            container.querySelector(`strong`).innerHTML = '"' + target.options[target.selectedIndex].text + '"'
            container.querySelector(`a`).href = window.absolute_homeurl + '/uploads/famtrip/' +  (value + '-' + window.currentLocale).toUpperCase() + '.pdf'
        }
    }

    checkFamtripProgramsChange(target) {
        let fl = true

        this.famtripProgramsSelects.forEach(function (element) {
            if (target.id == element.id) {
                // continue 
            } else {
                if (element.value && target.value.replace(/[0-9]/g, '') == element.value.replace(/[0-9]/g, '')) {
                    fl = false
                }

                /* exception DV2024 AURA1 shared with AURA and BFC */
                console.log('target.value : ' + target.value);
                console.log('element.value : ' + element.value);
                if (element.value &&
                    (
                        ( target.value.replace(/[0-9]/g, '') == 'AURA'  &&
                            element.value.replace(/[0-9]/g, '') == 'BFC'  ) ||
                        ( target.value.replace(/[0-9]/g, '') == 'BFC'  &&
                            element.value.replace(/[0-9]/g, '') == 'AURA'  )
                    ) ) {
                    fl = false
                }
            }
        })

        return fl
    }

    onIsFamtripParticipateSelectChange({ target }) {
        let value = parseInt(target.value),
            container =  this.querySelector(`#famtrip_programs`);

        if (!container) return false;
        let select = this.querySelector(`#${this.name}_famtripProgramsPriority1`)
        let select2 = this.querySelector(`#${this.name}_famtripProgramsPriority2`)
        let select3 = this.querySelector(`#${this.name}_famtripProgramsPriority3`)

        if (value) {
            container.removeAttribute('hidden')
            select.previousElementSibling.classList.add('required')
            select.setAttribute('required', 'required')
            select2.previousElementSibling.classList.add('required')
            select2.setAttribute('required', 'required')
            select3.previousElementSibling.classList.add('required')
            select3.setAttribute('required', 'required')

        } else {
            container.setAttribute('hidden', 'hidden')
            select.previousElementSibling.classList.remove('required')
            select.removeAttribute('required')
            select2.previousElementSibling.classList.remove('required')
            select2.removeAttribute('required')
            select3.previousElementSibling.classList.remove('required')
            select3.removeAttribute('required')

        }
    }

    onWantedActivitiesCheckboxChange({ target }) {
        let value = target.value;
        if (value !== 'others') return;

        let container =  this.querySelector(`#wanted_activities_other_container`),
            input = this.querySelector(`#${this.name}_wantedActivitiesOther`)

        if (target.checked) {
            container.removeAttribute('hidden')
            input.previousElementSibling.classList.add('required')
            input.setAttribute('required', 'required')
        } else {
            container.setAttribute('hidden', 'hidden')
            input.previousElementSibling.classList.remove('required')
            input.removeAttribute('required')
        }
    }
}
