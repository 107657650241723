import { scrollBottom } from '/assets/functions/animation.js'

export class ChatBox extends HTMLElement {
    constructor ({ username, conversationId } = {}) {
        super()
        if (username !== undefined) {
            this.username = username
        }
        if (conversationId !== undefined) {
            this.conversationId = conversationId
        }
        this.hubUrl = window.WORKSHOP.HUBURL

        this.loadMessages = this.loadMessages.bind(this)
        this.postMessage = this.postMessage.bind(this)

        this.messagesContainer = this.querySelector('#messages')
        this.inputContainer = this.querySelector('#input')
    }

    async connectedCallback () {
        this.username = this.username || this.getAttribute('username')
        this.conversationId = this.conversationId || this.getAttribute('conversation-id')
        if (!this.conversationId) {
            const response = await fetch('/conversations/1', { method: "POST" })
            if (response.status >= 200 && response.status < 300) {
                const data = await response.text()
                this.conversationId = data
            } else {
                console.error(response)
            }
            console.log(this.conversationId);
        }
        this.classList.add('chat')
        this.classList.add('chat-box')
        await this.loadMessages();

        this.inputContainer.querySelector('form').addEventListener('submit', this.postMessage)

        let url = new URL(this.hubUrl);
        url.searchParams.append('topic', `/conversations/${this.username}`)
        const eventSource = new EventSource(url, { withCredentials: true })
        eventSource.onmessage = (e) => {
            const message = JSON.parse(e.data)
            message.createdAt = new Date(message.createdAt)
            this.displayNewMessage(message)
        }
        scrollBottom(this.messagesContainer)
    }

    displayNewMessage (message) {
        this.messagesContainer.innerHTML += `
        <div class="message${ message.mine ? ' mine' : '' }">
            <div class="rounded">
                <p>${ message.content }</p>
            </div>
            <p class="small text-muted">${ message.createdAt.toLocaleString('fr-FR', { year: "numeric", month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" }) }</p>
        </div>
        `
        scrollBottom(this.messagesContainer)
    }

    async postMessage (e) {
        e.preventDefault()

        if (!this.conversationId) return;

        let formData = new FormData();
        let content = this.inputContainer.querySelector('input[type="text"]').value
        if (content === '') return;
        formData.append('content', content);

        fetch(`/messages/${this.conversationId}`, { method: "POST", body: formData })

        this.displayNewMessage({
            mine: true, content: content, createdAt: new Date()
        })

        this.inputContainer.querySelector('input[type="text"]').value = '';
    }

    async loadMessages (e) { 
        if (!this.conversationId) return console.log('No Conversation ID')
        const response = await fetch(`/messages/${this.conversationId}?ajax=1`)
        if (response.status >= 200 && response.status < 300) {
            const data = await response.text()
            this.messagesContainer.innerHTML = data
        } else {
            console.error(response)
        }
    }

}
