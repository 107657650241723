/**
 * Retourne une string normalisée, sans accents ni majuscules,
 */
export function normalizeString (str) {
    return str.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "");
}

export function wordStartWithInSentence (start, sentence) {
    let wordStartWithInSentence = false
    sentence.split(' ').forEach(word => {
        if (word.startsWith(start)) {
            wordStartWithInSentence = true
        }
    })
    return wordStartWithInSentence
}

export function uniqueId () {
    const n = Math.floor(Math.random() * 11);
    const k = Math.floor(Math.random() * 1000000);
    const m = String.fromCharCode(n) + k;
    return m
}

export function forceInputUppercase (e) {
    var start = e.target.selectionStart;
    var end = e.target.selectionEnd;
    e.target.value = e.target.value.toUpperCase();
    e.target.setSelectionRange(start, end);
}