// import { FetchForm, FormField, FormPrimaryButton } from '/assets/components/Form.jsx'
// import { useCallback, useEffect, useRef, useState } from 'preact/hooks'

/**
 * Formulaire de l'opération
 * @params {{onSubmit: function}} props
 */
export function CollaboratorForm ({ onSubmit, handleSuccess, collaborator = null }) {
    return null
    const [value, setValue] = useState({
        label: collaborator ? collaborator.label : ''
    })
    const action = collaborator ? `/api/collaborators/edit/${collaborator.id}` : `/api/collaborators/new`
    
    const onSuccess = useCallback(data => {
        onSubmit(data)
        handleSuccess(false)
    }, [onSubmit])
 
    return (
        <FetchForm action={action} className='stack' value={value} onChange={setValue} onSuccess={onSuccess} floatingAlert={true}>
            <FormField placeholder='Votre message' name='label' value={value.label} />
            <FormPrimaryButton>{collaborator ? `Éditer l'opération` : `Ajouter l'opération`}</FormPrimaryButton>
        </FetchForm>
    )
}