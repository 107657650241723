/**
 * 
 */
export default class UserModal extends HTMLElement {
    connectedCallback () {
        this.user_modal__details = this.querySelector('.user-modal__details')
        this.addEventListener('click', this.onClick.bind(this))
    }

    onClick (e) {
        e.preventDefault()
        const modal = document.createElement('modal-dialog')
        modal.setAttribute('overlay-close', 'overlay-close')
        modal.innerHTML = this.user_modal__details.innerHTML
        document.body.appendChild(modal)
    }
}
