import { createElement } from '/assets/functions/dom.js'
import {ttc} from '/assets/functions/price.js'
import { strToDom } from '/assets/functions/dom.js'
import { jsonFetch } from '/assets/functions/api.js'
import { flash } from '/assets/elements/Alert.js'
import { forceInputUppercase } from "../functions/string.js";

/**
 * Element permettant de 
 *
 * @property {HTMLTableElement} table
 * @property {HTMLDivElement} meetingList
 */
export class CollaboratorsUrlsVideoLogoFegistrationForm extends HTMLFormElement {
    async connectedCallback () {
        
    }

}
