import { strToDom } from '/assets/functions/dom.js'

/**
 * Element permettant de 
 *
 * @property {HTMLTableElement} table
 * @property {HTMLDivElement} meetingList
 */
export class VisitorIdentityRegistrationForm extends HTMLFormElement {
    async connectedCallback () {

        this.countrySelect = this.querySelector(`#${this.name}_country`)
        this.onCountrySelectChange({ target: this.countrySelect })
        this.countrySelect.addEventListener('change', e => this.onCountrySelectChange({ target: e.target }))

        this.activitiesServicesRegisteredTravelAgentRegisterContainer = this.querySelector(`#activities_services_registered_travel_agent_container`)
        this.activitiesServicesRegisteredTravelAgentRegisterSelect = this.querySelector(`#${this.name}_activitiesServicesRegisteredTravelAgentRegister`)
        this.activitiesServicesRegistrationNumberInput = this.querySelector(`#${this.name}_activitiesServicesRegistrationNumber`)
        this.activitiesServicesNoRegistrationNumberReasonInput = this.querySelector(`#${this.name}_activitiesServicesNoRegistrationNumberReason`)

        if (this.activitiesServicesRegisteredTravelAgentRegisterSelect) {
            this.onActivitiesServicesRegisteredTravelAgentRegisterSelectChange({ target: this.activitiesServicesRegisteredTravelAgentRegisterSelect })
            this.activitiesServicesRegisteredTravelAgentRegisterSelect.addEventListener('change', e => this.onActivitiesServicesRegisteredTravelAgentRegisterSelectChange({ target: e.target }))
        }


    }

    onCountrySelectChange({ target }) {
        let value = parseInt(target.value),
            container =  this.querySelector(`#activities_services_registered_travel_agent_container`);

        if (!container) return false;
        let select = this.querySelector(`#${this.name}_activitiesServicesRegisteredTravelAgentRegister`)
        let inputReason = this.querySelector(`#${this.name}_activitiesServicesNoRegistrationNumberReason`)

        if (value === 8) {
            container.removeAttribute('hidden')
            select.previousElementSibling.classList.add('required')
            select.setAttribute('required', 'required')
        } else {
            select.previousElementSibling.classList.remove('required')
            select.removeAttribute('required')

            this.hideElement(this.activitiesServicesRegistrationNumberInput)
            this.hideElement(this.activitiesServicesNoRegistrationNumberReasonInput)
            container.setAttribute('hidden', 'hidden')
        }
    }

    onActivitiesServicesRegisteredTravelAgentRegisterSelectChange({ target }) {
        if (target.value === '1') {
            this.showElement(this.activitiesServicesRegistrationNumberInput)
            this.hideElement(this.activitiesServicesNoRegistrationNumberReasonInput)
        } else if (target.value === '0') {
            this.showElement(this.activitiesServicesNoRegistrationNumberReasonInput)
            this.hideElement(this.activitiesServicesRegistrationNumberInput)
        } else {
            this.hideElement(this.activitiesServicesNoRegistrationNumberReasonInput)
            this.hideElement(this.activitiesServicesRegistrationNumberInput)
        }
    }

    showElement(element) {
        if( typeof element !== "undefined"){
            let container = element.closest('.conditional-logic-container')
            let elementLabel = container.querySelector('label')

            container.removeAttribute('hidden')
            elementLabel.classList.add('required')
            element.setAttribute('required', 'required')
        }

    }

    hideElement(element) {
        if( typeof element !== "undefined"){
            let container = element.closest('.conditional-logic-container')
            let elementLabel = container.querySelector('label')

            container.setAttribute('hidden', 'hidden')
            elementLabel.classList.add('required')
            element.removeAttribute('required')
            element.value = ''
        }

    }
}
