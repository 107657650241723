import { hasSameValue } from "/assets/functions/array";

/**
 * @property {HTMLInputElement}
 */
 export class ConditionalInput extends HTMLInputElement {
    constructor() {
        super();
        this.conditionalOpened = false

        this.onChange = this.onChange.bind(this)
    }

    connectedCallback () {
        this.conditionalContainer = this.closest('[hidden]')
        this.conditionalValues = this.getAttribute('conditional-values').split(' ')
        this.conditionalField = document.querySelector(`[id$="${this.getAttribute('conditional-field')}"]`)
        if (this.conditionalContainer) {
            this.conditionalLabels = this.conditionalContainer.querySelectorAll('label')
            this.conditionalInputs = this.conditionalContainer.querySelectorAll('input, select, textarea')
            
            this.conditionalContainer.classList.add('conditional-logic')
            this.conditionalContainer.style.setProperty('--max-height', `${this.conditionalContainer.scrollHeight}px`)
            
            document.addEventListener('conditional:changed', () => {
                this.conditionalContainer.style.setProperty('--max-height', `${this.conditionalContainer.scrollHeight}px`)
            })
            
            this.conditionalField.addEventListener('change', this.onChange)
            this.onChange()
        }
    }

    disconnectedCallback () {
        this.conditionalField.removeEventListener('change', this.onChange)
    }

    onChange (e) {
        console.log('changed!');
        let value = this.conditionalField.value
        if (this.conditionalField.type === 'checkbox') {
            value = this.conditionalField.checked.toString()
        }
        if (this.conditionalValues.includes(value)) {
            this.showConditionalContainer()
            console.log('show');
        } else {
            this.hideConditionalContainer()
            console.log('hide');
        }
        document.dispatchEvent(new Event('conditional:changed'))
    }

    showConditionalContainer () {
        this.conditionalContainer.removeAttribute('hidden')
        this.conditionalLabels.forEach(label => label.classList.add('required'))
        this.conditionalInputs.forEach(input => input.setAttribute('required', 'required'))
        this.conditionalOpened = true
    }
    
    hideConditionalContainer () {
        this.conditionalContainer.setAttribute('hidden', 'hidden')
        this.conditionalLabels.forEach(label => label.classList.remove('required'))
        this.conditionalInputs.forEach(input => input.removeAttribute('required', 'required'))
        //this.conditionalInputs.value = ''
        this.conditionalOpened = false
    }
}

export class ConditionalInputMultiple extends ConditionalInput {

    onChange (e) {
        if (this.conditionalField.tagName === 'SELECT') {
            const options = this.conditionalField.selectedOptions;
            var values = Array.from(options).map(({ value }) => value);
        } else {
            let inputs = this.conditionalField.querySelectorAll('input')
            inputs = Array.from(inputs).filter(input => input.checked)
            var values = Array.from(inputs).map(({ checked, value }) => checked ? value : null);
        }
        if (hasSameValue(values, this.conditionalValues)) {
            this.showConditionalContainer()
        } else {
            this.hideConditionalContainer()
        }
        document.dispatchEvent(new Event('conditional:changed'))
    }

}