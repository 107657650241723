import { uniqueId } from '/assets/functions/string.js'

/**
 * Icône basée sur la sprite SVG
 * @param {{name: string}} props
 */
export function Switch ({ id = uniqueId(), children, defaultChecked = false, disabled = false, handleChange = () => {}, ...props }) {
    
    return (
        <div class="form-check">
            <input id={id} type="checkbox" is="input-switch" defaultChecked={defaultChecked} disabled={disabled} onChange={handleChange} {...props} />
            <label htmlFor={id} class="form-check-label">{ children }</label>
        </div>
    )
}