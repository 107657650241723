export function formatDatetime(strDate) {
    const locale = Intl.DateTimeFormat().resolvedOptions().locale
    const date = new Date(strDate)
    // const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit', second: 'numeric' }
    const options = { year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: 'numeric' }
    
    return date.toLocaleString(locale, options).capitalize()

}
export function formatDate(strDate) {
    const locale = Intl.DateTimeFormat().resolvedOptions().locale
    const date = new Date(strDate)
    // const options = { weekday: 'long', year: 'numeric', month: 'short', day: 'numeric', hour: 'numeric', minute: '2-digit', second: 'numeric' }
    const options = { year: 'numeric', month: 'short', day: 'numeric' }
    
    return date.toLocaleString(locale, options).capitalize()
}