import { useEffect, useState, useCallback } from 'preact/hooks'

/**
 * Alterne une valeur
 */
export function useToggle (initialValue = null) {
    const [value, setValue] = useState(initialValue)
    return [value, useCallback(() => setValue(v => !v), [])]
}

/**
 * useEffect pour une fonction asynchrone
 */
export function useAsyncEffect (fn, deps = []) {
    /* eslint-disable */
    useEffect(() => {
        fn()
    }, deps)
    /* eslint-enable */
}