
import { useCallback, useEffect, useMemo, useRef, useState } from 'preact/hooks'
import { addCollaborator, deleteCollaborator, getAllCollaborators, getCollaboratorsRemaining, getCollaboratorsPlanningRemaining, updateCollaborator, toggleCollaboratorPlanning } from '/assets/api/collaborators.js'
import { useAsyncEffect } from '/assets/functions/hooks.js'
import { scrollTo } from '/assets/functions/animation.js'
import { Collaborator } from './Collaborator.jsx'
import { Button, IconButton } from '../Button.jsx'
import { CollaboratorModal } from './CollaboratorModal.jsx'
import { Spinner } from '../Animation/Spinner.jsx'
import { ApiError } from '../../functions/api.js'


/**
 * Affiche les collaborateurs associées à un compte en banque
 *
 */
export function Collaborators ({ target, children }) {
    const [state, setState] = useState({
        editing: null, // ID de l'collaborateur en cours d'édition
        collaborators: null, // Liste des collaborateurs
        remaining: 0, // Nombre des collaborateurs restant que l'on peut inscrire
        planningRemaining: 0, // Nombre des collaborateurs restant que l'on peut inscrire
    })
    const [showCollaboratorModal, setShowCollaboratorModal] = useState(false)
    const [collaboratorsLoaded, setCollaboratorsLoaded] = useState(false)
    const count = state.collaborators ? state.collaborators.length : null

    const collaborators = useMemo(() => {
        if (state.collaborators === null) {
            return null
        }
        return state.collaborators
    }, [state.collaborators])

    const handleSubmit = async (data) => {
        const newCollaborator = await handleCreate(data)
        if (!(newCollaborator instanceof ApiError)) {
            setShowCollaboratorModal(false)
        }
    }

    // On crée un nouveau collaborator
    const handleCreate = useCallback(async (data) => {
        data = { ...data }
        const newCollaborator = await addCollaborator(target, data)
        setState(s => ({
            ...s,
            collaborators: [...s.collaborators, newCollaborator]
        }))
        return newCollaborator
    }, [])

    // On commence l'édition d'une collaborateur
    const handleEdit = useCallback(collaborator => {
        // setState(s => ({ ...s, editing: s.editing === collaborator.id ? null : collaborator.id }))
    }, [])

    // On met à jour (via l'API)
    const handleUpdate = useCallback(async (id, data) => {
        const newCollaborator = { ...(await updateCollaborator(target, id, data)) }
        setState(s => ({
            ...s,
            editing: null,
            collaborators: s.collaborators.map(c => (c.id === newCollaborator.id ? newCollaborator : c))
        }))
    }, [])
    
    const handleDelete = useCallback(async (data) => {
        if (confirm(trans.confirmDeletion)) {
            await deleteCollaborator(target, data.id)
        }
    }, [])

    // On met à jour (via l'API)
    const handleToggleCanHavePlanning = useCallback(async (id) => {
        const newCollaborator = { ...(await toggleCollaboratorPlanning(target, id)) }
        setState(s => ({
            ...s,
            editing: null,
            collaborators: s.collaborators.map(c => (c.id === newCollaborator.id ? newCollaborator : c))
        }))
        return newCollaborator
    }, [])

    // On charge les collaborators dès l'affichage du composant
    useAsyncEffect(async () => {
        const collaborators = await getAllCollaborators(target)
        setCollaboratorsLoaded(true)
        setState(s => ({ ...s, collaborators }))
    }, [])

    useAsyncEffect(async () => {
        if (collaboratorsLoaded) {
            const remaining = await getCollaboratorsRemaining(target)
            setState(s => ({ ...s, remaining }))
        }
    }, [collaborators])

    useAsyncEffect(async () => {
        if (collaboratorsLoaded && target == "exhibitor") {
            const planningRemaining = await getCollaboratorsPlanningRemaining(target)
            setState(s => ({ ...s, planningRemaining }))
        }
    }, [collaborators])

    return (
        <div className='collaborator-area stack mt4' style={{ '--gap': 4 }}>
            <div className='collaborators__title'>
                { target === "visitor" ? (
                    <>{count === null ? (
                        <h2></h2>
                    ) : (
                        <h2>
                            <strong>{count + state.remaining}</strong> FULL PASS
                        </h2>
                    )}</>
                ) : (
                    <>{count === null ? (
                        <h2>
                            <strong>0</strong>  { trans.noOne }
                            <br />
                            ({ trans.noPlanning })
                        </h2>
                    ) : (
                        <h2>
                            <strong>{count}</strong> collaborateur{count > 1 ? 's' : ''} sur cette inscription ({state.remaining} supp. possible{state.remaining > 1 ? 's' : ''})
                            <br />
                            ({state.planningRemaining} planning{state.planningRemaining > 1 ? 's' : ''} possible{state.planningRemaining > 1 ? 's' : ''})
                        </h2>
                    )}</>
                )}      
            </div>
            <hr />
            {children}
            <table className='table collaborators'>
                <thead>
                    <tr>
                        <th>{trans.civilite}</th>
                        <th>{trans.firstname}</th>
                        <th>{trans.lastname}</th>
                        <th>{trans.email}</th>
                        <th>{trans.position}</th>
                        {target == "exhibitor" &&
                            <th>{trans.planning}</th>
                        }
                        <th>{trans.actions}</th>
                    </tr>
                </thead>
                <tbody className="collaborator-list list-group">
                    {collaborators ? (
                        collaborators.map(collaborator => {
                            if (!collaborator) return null

                            return (
                                <Collaborator
                                    key={collaborator.id}
                                    collaborator={collaborator}
                                    editing={state.editing === collaborator.id}
                                    onEdit={handleEdit}
                                    onUpdate={handleUpdate}
                                    onDelete={handleDelete}
                                    onToggleCanHavePlanning={handleToggleCanHavePlanning}
                                    canHavePlanning={state.planningRemaining >= 1}
                                    target={target}
                                />
                            )
                        })
                    ) : (
                        <tr>
                            <td colSpan={7}>
                                <Spinner />
                            </td>
                        </tr>
                    )}
                    {canEditForm && state.remaining >= 1 && (
                        <tr>
                            <td colSpan={7}>
                                <IconButton className="left-icon" icon='plus' onClick={() => setShowCollaboratorModal(true)}>
                                    {trans.newCollaborator}
                                </IconButton>
                                <CollaboratorModal show={showCollaboratorModal} onClose={() => setShowCollaboratorModal(false)} onSubmit={handleSubmit} target={target} />
                            </td>
                        </tr>
                    )}
                </tbody>
            </table>
        </div>
    )
}
  