import htm from "htm/mini";

/**
 * Replace un élément si l'un de ses côté dépasse de la fenêtre
 *
 * @param {HTMLElement} element
 */
 export function replaceIfExceedsTheFrame(element) {
    var rect = element.getBoundingClientRect();

    if (rect.left - 55 <= 0) {
        element.style.left = 0
    } else if (rect.right + 55 >= (window.innerWidth || document.documentElement.clientWidth)) {
        element.style.right = 0
        element.style.left = 'unset'
    }
}

/**
 * Trouve la position de l'élément par rapport au haut de la page de manière recursive
 *
 * @param {HTMLElement} element
 * @param {HTMLElement|null} parent
 */
export function offsetTop(element, parent = null) {
  let top = element.offsetTop;
  while ((element = element.offsetParent)) {
    if (parent === element) {
      return top;
    }
    top += element.offsetTop;
  }
  return top;
}

/**
 * Crée un élément HTML
 *
 * Cette fonction ne couvre que les besoins de l'application, jsx-dom pourrait remplacer cette fonction
 *
 * @param {string} tagName
 * @param {object} attributes
 * @param {string} html
 * @return HTMLElement
 */
export function createElement(tagName, attributes = {}, html = null) {
    if (typeof tagName === "function") {
        return tagName(attributes);
    }

    const svgTags = ["svg", "use", "path", "circle", "g"];
    // On construit l'élément
    const e = !svgTags.includes(tagName)
        ? document.createElement(tagName)
        : document.createElementNS("http://www.w3.org/2000/svg", tagName);

    // On lui associe les bons attributs
    for (const k of Object.keys(attributes || {})) {
        if (typeof attributes[k] === "function" && k.startsWith("on")) {
        e.addEventListener(k.substr(2).toLowerCase(), attributes[k]);
        } else if (k === "xlink:href") {
        e.setAttributeNS("http://www.w3.org/1999/xlink", "href", attributes[k]);
        } else {
        e.setAttribute(k, attributes[k]);
        }
    }

    e.innerHTML = html;
  
    return e;
}

/**
 * CreateElement version Tagged templates
 * @type {(strings: TemplateStringsArray, ...values: any[]) => (HTMLElement[] | HTMLElement)}
 */
export const html = htm.bind(createElement);

/**
 * Transform une chaine en élément DOM
 * @param {string} str
 * @return {DocumentFragment}
 */
export function strToDom(str) {
  return document.createRange().createContextualFragment(str).firstChild;
}

/**
 *
 * @param {HTMLElement|Document|Node} element
 * @param {string} selector
 * @return {null|HTMLElement}
 */
export function closest(element, selector) {
  for (; element && element !== document; element = element.parentNode) {
    if (element.matches(selector)) return element;
  }
  return null;
}

/**
 * @param {string} selector
 * @return {HTMLElement}
 */
export function $(selector) {
  return document.querySelector(selector);
}

/**
 * @param {string} selector
 * @return {HTMLElement[]}
 */
export function $$(selector) {
  return Array.from(document.querySelectorAll(selector));
}

/**
 * Génère une classe à partir de différentes variables
 *
 * @param  {...string|null} classnames
 */
export function classNames(...classnames) {
  return classnames.filter((classname) => classname !== null && classname !== false).join(" ");
}

/**
 * Convertit les données d'un formulaire en objet JavaScript
 *
 * @param {HTMLFormElement} form
 * @return {{[p: string]: string}}
 */
export function formDataToObj(form) {
  return Object.fromEntries(new FormData(form));
}
