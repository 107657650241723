export function Spinner ({ margin = 10 }) {
    const style = {
        margin: `${margin}px auto`
    }
    
    return <div className="loading-spinner" style={ style }>
        {[...Array(3)].map(() =>
            <span />
        )}
    </div>
}
