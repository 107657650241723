import { createElement } from '/assets/functions/dom.js'
import {displayPriceTtc, displayPriceHt, ttc} from '/assets/functions/price.js'

/**
 * Element permettant de 
 *
 * @property {HTMLTableElement} table
 * @property {HTMLDivElement} meetingList
 */
export class ExhibitorServicesRegistrationForm extends HTMLFormElement {
    async connectedCallback () {
        // Services Vars
        this.servicesContainer = this.querySelector('.services');
        this.servicesTotal = this.servicesContainer.querySelector('tr.total')
        this.additionalPeopleSelects = this.servicesContainer.querySelectorAll(`tr.additionnal-people [id^='${this.name}_']`)
        this.servicesSelects = this.servicesContainer.querySelectorAll(`tr [id^='${this.name}_']`)
        this.standSelects = this.servicesContainer.querySelectorAll(`tr.stand [id^='${this.name}_']`)
        // Présentations Vars
        this.presencesContainer = this.querySelector('.presences');
        this.presencesTotal = this.presencesContainer.querySelector('tr.total')
        this.presencesSelects = this.presencesContainer.querySelectorAll(`tr [id^='${this.name}_']`)
        // Total général
        this.generalTotal = this.querySelector('#general-total')

        this.additionalPeopleSelects.forEach((additionalPeopleSelect) => {
            this.onAdditionalPeopleSelectChange({ additionalPeopleSelect: additionalPeopleSelect })
            additionalPeopleSelect.addEventListener('change', e => this.onAdditionalPeopleSelectChange({ additionalPeopleSelect: e.target }))
        })

        // Services Functions
        this.servicesSelects.forEach(servicesSelect => {
            this.initSelect(servicesSelect)
            this.onSelectChange({ target: servicesSelect })
            servicesSelect.addEventListener('change', e => this.onSelectChange({ target: e.target }))
        });

        this.standSelects.forEach(standSelect => {
            this.onStandSelectChange({ target: standSelect })
            standSelect.addEventListener('change', e => this.onStandSelectChange({ target: e.target }))
        });

        // Présentations Functions
        this.presencesSelects.forEach(presencesSelect => {
            this.initSelect(presencesSelect)
            this.onSelectChange({ target: presencesSelect })
            presencesSelect.addEventListener('change', e => this.onSelectChange({ target: e.target }))
        });
    }

    initSelect(select) {
        for (let i = 0; i < this.additionalPeopleSelects.length; i++) {
            const additionalPeopleSelect = this.additionalPeopleSelects[i];            
            if (select === additionalPeopleSelect) {
                return;
            }
        }
        let maxQuantity = select.getAttribute('data-max-quantity');

        select.querySelectorAll('option').forEach(option => {
            if (parseInt(option.value) > parseInt(maxQuantity)) {
                option.remove();
            }
        });
    }

    onAdditionalPeopleSelectChange({ additionalPeopleSelect }) {
        // On charge le nombre de possible
        let optionsNb = 0
        if (this.standSelects[0].value) {
            optionsNb = 1
        }
        this.additionalPeopleSelects.forEach((additionalPeopleSelect) => {
            // On enlève toutes les options
            let currentAdditionalPeopleSelectIndex = additionalPeopleSelect.selectedIndex;
            additionalPeopleSelect.querySelectorAll('option').forEach(option => {
                if (option.value) {
                    option.remove();
                }
            });
            // On remet les options possibles
            for (let i = 1; i <= optionsNb; i++) {
                let option = createElement('option', { value: i }, i);
                
                if (i == currentAdditionalPeopleSelectIndex) {
                    option.setAttribute('selected', 'selected');
                }
                additionalPeopleSelect.append(option)
            }
        })

        this.onSelectChange({ target: additionalPeopleSelect })
    }

    onAdditionalPeopleSelectChangeOLD({ additionalPeopleSelect }) {
        // On charge le nombre de possible
        let optionsNb = 10;
        if (!window.is_partner) {
            optionsNb = 0
            if (this.standSelects[0].value) {
                optionsNb = 1
            }
        //     this.standSelects.forEach(standSelect => {
        //         let maxAdditionalPeople = parseInt(standSelect.getAttribute('data-max-additional-people'))
        //         let standSelectValue = standSelect.value ? parseInt(standSelect.value) : 0
        //         optionsNb = optionsNb + parseInt(standSelectValue) * maxAdditionalPeople
        //     })
        }
        // On charge le nombre de pris
        let takenNb = 0
        this.additionalPeopleSelects.forEach(additionalPeopleSelect => {
            let additionalPeopleSelectValue = additionalPeopleSelect.value ? parseInt(additionalPeopleSelect.value) : 0
            takenNb = takenNb + parseInt(additionalPeopleSelectValue)
        });
        this.additionalPeopleSelects.forEach((additionalPeopleSelect) => {
            // On enlève toutes les options
            let currentAdditionalPeopleSelectIndex = additionalPeopleSelect.selectedIndex;
            let additionalPeopleSelectValue = additionalPeopleSelect.value ? parseInt(additionalPeopleSelect.value) : 0
            additionalPeopleSelect.querySelectorAll('option').forEach(option => {
                if (option.value) {
                    option.remove();
                }
            });
            // On remet les options possibles
            for (let i = 1; i <= optionsNb - takenNb + additionalPeopleSelectValue; i++) {
                let option = createElement('option', { value: i }, i);
                
                if (i == currentAdditionalPeopleSelectIndex) {
                    option.setAttribute('selected', 'selected');
                }
                additionalPeopleSelect.append(option)
            }
        })

        this.onSelectChange({ target: additionalPeopleSelect })
    }

    onSelectChange({ target }) {
        let price = parseInt(target.getAttribute('data-price'))
        let quantity = target.value === '' ? 0 : parseInt(target.value)

        let isPartner = window.is_partner;
        let productId = parseInt(target.getAttribute('data-product-id'));

        /* the product : STAND CLASSIQUE - 6 stands maxi par société has the id : 1 */
        let amount = 0;
        if (isPartner == 1) {

            if (productId == 1) {
                if (quantity > 1) {
                    amount = price * (quantity - 1);
                    this.querySelector('.obligatoryItem .amount').innerText = displayPriceHt(amount);
                    this.querySelector('.obligatoryItem .ttc-amount').innerText = displayPriceTtc(amount);
                } else {
                    amount = 0;
                    this.querySelector('.obligatoryItem .amount').innerText = displayPriceHt(amount);
                    this.querySelector('.obligatoryItem .ttc-amount').innerText = displayPriceTtc(amount);
                }
            }
        } else {
            amount = price * quantity;
        }

        let $container = target.closest('tr')
        let $amount = $container.querySelector('.amount')
        let $ttcAmount = $container.querySelector('.ttc-amount')

        $amount.innerText = displayPriceHt(amount);
        $ttcAmount.innerText = displayPriceTtc(amount)

        this.calculateTotals()
    }

    showStandSharedWithCompanyContainer(){
        if($('#standSharedWithCompany-container').length){
            $('#standSharedWithCompany-container').attr('hidden', false);
            $('#standSharedWithCompany-container').find('input').attr('required', true);
        }
    }

    hideStandSharedWithCompanyContainer(){
        if($('#standSharedWithCompany-container').length){
            $('#standSharedWithCompany-container').find('input').attr('required', false);
            $('#standSharedWithCompany-container').find('input').val('');
            $('#standSharedWithCompany-container').attr('hidden', true);
        }
    }

    onStandSelectChange({ target }) {
        this.additionalPeopleSelects.forEach((additionalPeopleSelect) => {
            this.onAdditionalPeopleSelectChange({ additionalPeopleSelect: additionalPeopleSelect})
        })

        if (target.value) {
            this.standSelects.forEach(standSelect => {  
                if (standSelect !== target) standSelect.disabled = true
            });
            if( target.dataset.productId == 2 ){
                this.showStandSharedWithCompanyContainer()
            }
        } else {
            if( target.dataset.productId == 2 ){
                this.hideStandSharedWithCompanyContainer()
            }
            this.standSelects.forEach(standSelect => {  
                if (standSelect !== target) standSelect.disabled = false
            });
        }
    }

    calculateTotals() {
        // Montant services
        let servicesAmount = 0

        this.servicesSelects.forEach(select => {
            if (!select.value) return;

            let selectPrice = parseInt(select.getAttribute('data-price'))
            let selectQuantity = select.value === '' ? 0 : parseInt(select.value)

            let isPartner = window.is_partner;
            let productId = parseInt(select.getAttribute('data-product-id'));

            /* the product : STAND CLASSIQUE - 6 stands maxi par société has the id : 1 */
            let selectAmount = selectPrice * selectQuantity;
            if (isPartner == 1) {
                if (productId == 1) {
                    selectAmount = 0;
                    if (selectQuantity > 1) {
                        selectAmount = selectPrice * (selectQuantity - 1);
                    }
                }

                if ( productId == 3 ) {
                    if (this.querySelector('#services_registration_form_stands_0_quantity').value == 1) {
                        selectAmount = 0;
                    } else {
                        selectAmount = selectPrice;
                    }
                }

            }

            servicesAmount = servicesAmount + selectAmount

            select.closest('tr').querySelector('.amount').innerText = displayPriceHt(selectAmount);
            select.closest('tr').querySelector('.ttc-amount').innerText = displayPriceTtc(selectAmount);
        });

        this.servicesTotal.querySelector('.amount').innerText = displayPriceHt(servicesAmount)
        this.servicesTotal.querySelector('.ttc-amount').innerText = displayPriceTtc(servicesAmount)

        // Montant présentations
        let presencesAmount = 0

        this.presencesSelects.forEach(select => {
            if (!select.value) return;

            let selectPrice = parseInt(select.getAttribute('data-price'))
            let selectQuantity = select.value === '' ? 0 : parseInt(select.value)
            let selectAmount = selectPrice * selectQuantity

            presencesAmount = presencesAmount + selectAmount
            select.closest('tr').querySelector('.amount').innerText = displayPriceHt(selectAmount);
            select.closest('tr').querySelector('.ttc-amount').innerText = displayPriceTtc(selectAmount);
        });

        this.presencesTotal.querySelector('.amount').innerText = displayPriceHt(presencesAmount)
        this.presencesTotal.querySelector('.ttc-amount').innerText = displayPriceTtc(presencesAmount);
        
        // Montant général
        let generalAmount = servicesAmount + presencesAmount

        this.generalTotal.querySelector('.amount').innerText = displayPriceHt(generalAmount);
        this.generalTotal.querySelector('.ttc-amount').innerText = displayPriceTtc(generalAmount);
    }

    async fetch(target, nextTarget) {
        // let data = await fetch(this.action, {
        //         method: this.getAttribute('method'),
        //         body: target.name + '=' + target.value,
        //         headers: { 'Content-Type': 'application/x-www-form-urlencoded; charset: UTF-8' }
        //     })
        //     .then(response => response.text())
        //     .then(html => createElement('html', {}, html).querySelector(`#${nextTarget.id}`))
        //     .catch(error => console.log(error))
        // ;

        // return data;
    }

}
