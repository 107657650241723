export class Accordion extends HTMLElement {
    constructor() {
        super()
        this.openSection = this.openSection.bind(this)
        this.closeSection = this.closeSection.bind(this)
    }

    connectedCallback () {
        this.classList.add('accordion')

        // this.querySelector('.alert-close').addEventListener('click', e => {
        //     e.preventDefault()
        //     this.close()
        // })

        this.sections = this.getElementsByClassName('accordion__section');
        for (let section of this.sections) {
            section.addEventListener('click', e => {
                e.preventDefault()
                this.listenClick(e, section)
            })
        }
    }

    listenClick(e, section) {
        if (e.target !== section && !e.target.classList.contains('accordion__section_title')) {
            return;
        }
        // for (let s of this.sections) {
        //     if (s !== section) {
        //         this.closeSection(s);
        //     }
        // }
        section.classList.contains('active') ? this.closeSection(section) : this.openSection(section);
    }

    openSection(section) {
        section.classList.add('active');

        let content = section.querySelector('.accordion__section_content');
        content.style.padding = "20px 20px 0";
        content.style.maxHeight = content.scrollHeight + 40 + "px";
    }

    closeSection(section) {
        section.classList.remove('active');

        let content = section.querySelector('.accordion__section_content');
        content.style.padding = "0 20px";
        content.style.maxHeight = null;
    }

}