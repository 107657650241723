import preactCustomElement from '/assets/functions/preact.js'

import { ChatConversations } from './ChatConversations.js';
import { ChatBox } from './ChatBox.js';
import { Alert, FloatingAlert } from './Alert.js';
import ToolTip from './ToolTip.js'
import { Accordion } from './Accordion.js';
import { InteractivePlan } from './InteractivePlan.js';

import { ExhibitorServicesRegistrationForm } from './exhibitor/registration/ExhibitorServicesRegistrationForm.js';
import { VisitorIdentityRegistrationForm } from './visitor/registration/VisitorIdentityRegistrationForm.js';
import { VisitorActivityRegistrationForm } from './visitor/registration/VisitorActivityRegistrationForm.js';
import { VisitorServicesRegistrationForm } from './visitor/registration/VisitorServicesRegistrationForm.js';
import { CollaboratorsUrlsVideoLogoFegistrationForm } from './CollaboratorsUrlsVideoLogoFegistrationForm.js';

import { Switch } from './Switch.js';
import { AutoSubmit } from './AutoSubmit.js'
import { ConditionalSelect, ConditionalSelectMultiple} from './ConditionalSelect.js';
import { ConditionalInput, ConditionalInputMultiple} from './ConditionalInput.js';
import './Planning.js';
import ListUserFilters from './ListUserFilters.js';
import UserModal from './UserModal.js';
import CustomChart from './CustomChart.js';
import { Collaborators } from '/assets/components/Collaborator/Collaborators.jsx'
import { Modal } from '/assets/components/Modal.jsx';
import { Spinner } from '/assets/components/Animation/Spinner.jsx'

customElements.define('chat-conversations', ChatConversations);
customElements.define('chat-box', ChatBox);
customElements.define('alert-message', Alert);
customElements.define('alert-floating', FloatingAlert);
customElements.define('list-user-filters', ListUserFilters);
customElements.define('user-modal', UserModal);
customElements.define('custom-chart', CustomChart);
customElements.define('tool-tip', ToolTip)
customElements.define('accordion-container', Accordion)
customElements.define('interactive-plan', InteractivePlan)

customElements.define('exhibitor-services-registration-form', ExhibitorServicesRegistrationForm, { extends: 'form' });
customElements.define('visitor-identity-registration-form', VisitorIdentityRegistrationForm, { extends: 'form' });
customElements.define('visitor-activity-registration-form', VisitorActivityRegistrationForm, { extends: 'form' });
customElements.define('visitor-services-registration-form', VisitorServicesRegistrationForm, { extends: 'form' });

customElements.define('collaborators-urls-video-logo-registration-form', CollaboratorsUrlsVideoLogoFegistrationForm, { extends: 'form' });

preactCustomElement('collaborators-registration-area', Collaborators, ['target'])
preactCustomElement('modal-dialog', Modal)
preactCustomElement('loading-spinner', Spinner)

// CustomElement étendus
customElements.define('input-switch', Switch, { extends: 'input' });
customElements.define('auto-submit', AutoSubmit, { extends: 'form' });
customElements.define('conditional-select', ConditionalSelect, { extends: 'select' });
customElements.define('conditional-select-multiple', ConditionalSelectMultiple, { extends: 'select' });
customElements.define('conditional-input', ConditionalInput, { extends: 'input' });
customElements.define('conditional-input-multiple', ConditionalInputMultiple, { extends: 'input' });

