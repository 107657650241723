import { replaceIfExceedsTheFrame } from "/assets/functions/dom"

/**
 * @property { string } message Texte de la tool-tip
 * @property { string } icon Icône de la tool-tip
 */
export default class ToolTip extends HTMLElement {
    constructor ({ icon, label, message } = {}) {
        super()
        this.icon = icon
        this.label = label
        this.message = message
    }

    connectedCallback () {
        if( window.ag_environnement && window.ag_environnement == "admin"){
            return;
        }
        this.classList.add('tooltip')
        this.icon = this.icon || this.getAttribute('icon')
        this.label = this.label || this.getAttribute('label')

        let label;
        if (this.label) {
            label = `<span class="tooltip__label">${this.label}</span>`
        } else {
            label = `<svg class="icon icon-${this.icon}">
                <use xlink:href="/sprite.svg#${this.icon}"></use>
            </svg>`
        }

        const text = this.innerHTML
        this.innerHTML = `
        ${label}
        <div class="tooltip__content">
            ${this.message || text}
        </div>
        <span class="tooltip__indicator"></span>`

        // Si la Tooltip dépasse de la gauche ou de droite on la replace
        // (de base on essaie de la possitionner au milieu par rapport à la flèche)
        // const tooltip__content = this.querySelector('.tooltip__content')
        // replaceIfExceedsTheFrame(tooltip__content)
    }
}
