import { Spinner } from './Animation/Spinner.jsx'
import { Icon } from './Icon.jsx'
import { classNames } from '/assets/functions/dom.js'

export function PrimaryButton ({ children, ...props }) {
    return (
        <Button className='btn-primary' {...props}>
            {children}
        </Button>
    )
}

export function OutlinedPrimaryButton ({ children, ...props }) {
    return (
        <Button className='btn-primary-outlined' {...props}>
           {children}
        </Button>
    )
}

/**
 *
 * @param {*} children
 * @param {string} className
 * @param {string} size
 * @param {boolean} loading
 * @param {Object} props
 * @return {*}
 */
export function Button ({ children, className = '', loading = false, size, ...props }) {
    className = classNames('btn', className, size && `btn-${size}`)
    return (
        <button className={className} disabled={loading} {...props}>
            {loading && <Spinner />}
            {children}
        </button>
    )
}

export function IconButton ({ icon, children, className = '', loading = false, size, ...props }) {
    className = classNames('btn-primary btn-icon', className, size && `btn-${size}`, loading && `btn-loading`)
    return (
        <button className={className} disabled={loading} {...props}>
            {loading ? <Loader className='icon' /> : <Icon name={icon} />}
            <span>{children}</span>
        </button>
    )
}