import { createElement } from '/assets/functions/dom.js'
import {displayPriceTtc, displayPriceHt, ttc} from '/assets/functions/price.js'

/**
 * Element permettant de 
 *
 * @property {HTMLTableElement} table
 * @property {HTMLDivElement} meetingList
 */
export class VisitorServicesRegistrationForm extends HTMLFormElement {
    async connectedCallback () {
        // Services Vars
        this.servicesContainer = this.querySelector('.services');
        this.servicesTotal = this.servicesContainer.querySelector('tr.total')
        this.servicesSelects = this.servicesContainer.querySelectorAll(`tr [id^='${this.name}_']`)
        this.productSelects = this.servicesContainer.querySelectorAll(`tr.product [id^='${this.name}_']`)
        
        // Services Functions
        this.servicesSelects.forEach(servicesSelect => {
            this.initSelect(servicesSelect)
            this.onSelectChange({ target: servicesSelect })
            servicesSelect.addEventListener('change', e => this.onSelectChange({ target: e.target }))
        });

        // if (this.productSelects.length > 1) {
        //     this.productSelects.forEach(productSelect => {
        //         this.onProductSelectChange({ target: productSelect })
        //         productSelect.addEventListener('change', e => this.onProductSelectChange({ target: e.target }))
        //     });
        // }
    }

    initSelect(select) {
        if (select.value == "") {
            select.value = 1
        }
        let maxQuantity = select.getAttribute('data-max-quantity');

        select.querySelectorAll('option').forEach(option => {
            if (option.value == "") {
                option.remove();
            }
            if (parseInt(option.value) > parseInt(maxQuantity)) {
                option.remove();
            }
        });
    }

    onSelectChange({ target }) {
        let price = parseInt(target.getAttribute('data-price'))
        let quantity = target.value === '' ? 0 : parseInt(target.value)

        let amount = price * quantity;

        let $container = target.closest('tr')
        let $amount = $container.querySelector('.amount')
        let $ttcAmount = $container.querySelector('.ttc-amount')

        $amount.innerText = displayPriceHt(amount);
        $ttcAmount.innerText = displayPriceTtc(amount)

        this.calculateTotals()
    }

    onProductSelectChange({ target }) {
        let maxItemsNb = 10
        let itemsNb = 0
        
        this.productSelects.forEach(productSelect => {
            let productSelectValue = productSelect.value === "" ? 0 : parseInt(productSelect.value)
            itemsNb = itemsNb + productSelectValue
        });

        let restNb = maxItemsNb - itemsNb

        this.productSelects.forEach(productSelect => {
            let productSelectValue = productSelect.value === "" ? 0 : parseInt(productSelect.value)
            productSelect.querySelectorAll('option').forEach(option => {
                if (parseInt(option.value) > productSelectValue + restNb) {
                    option.remove();
                }
            });

            let selectOptionsNb = productSelectValue + restNb
            for (let i = 1; i <= selectOptionsNb; i++) {
                if (i >= productSelect.length) {
                    let option = createElement('option', { value: i }, i);
                    productSelect.append(option)
                }
            }
        });
    }

    calculateTotals() {
        // Montant services
        let servicesAmount = 0

        this.servicesSelects.forEach(select => {
            if (!select.value) return;

            let selectPrice = select.getAttribute('data-price')
            let selectQuantity = select.value === '' ? 0 : parseInt(select.value)

            let selectAmount = selectPrice * selectQuantity;

            servicesAmount = servicesAmount + selectAmount

            select.closest('tr').querySelector('.amount').innerText = displayPriceHt(selectAmount);
            select.closest('tr').querySelector('.ttc-amount').innerText = displayPriceTtc(selectAmount);
        });

        this.servicesTotal.querySelector('.amount').innerText = displayPriceHt(servicesAmount)
        this.servicesTotal.querySelector('.ttc-amount').innerText = displayPriceTtc(servicesAmount)
    }

}
